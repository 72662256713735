import { Button, Dialog, IconButton, Paper, Typography } from '@mui/material';
import OrgGroupsTable from './OrgGroupsTable/OrgGroupsTable';
import { useContext, useState } from 'react';
import OrgGroupsEditForm from './OrgGroupsEditForm';
import OrgGroupsTabView from './OrgGroupsTabs/OrgGroupsTabView';
import { Folder, List } from '@mui/icons-material';
import { UserContext } from '../context/UserStateManager';
import { useQuery, useQueryClient } from 'react-query';
import { DeviceModel } from '../devices/Devices';
import { Group } from './orgTypes';
import { getUngroupDevices } from './groupsHelpers';
import { ToastContainer } from 'react-toastify';

export default function OrgGroups() {
  const [editDialogState, setEditDialogState] = useState(false);
  const [tabView, setTabView] = useState(false);
  const [editGroup, setEditGroup] = useState<Group>();
  const [editPath, setEditPath] = useState<string>();
  const queryClient = useQueryClient();

  const userCon = useContext(UserContext);

  const unGroupedDevices = useQuery<DeviceModel[]>(
    ['getUngroupedDevices', userCon.state.currentOrg],
    async () => await getUngroupDevices(userCon),
  );

  const groupTree = userCon.state.currentOrgGroupTree || [];

  return (
    <>
      <Paper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h3">Organization Groups</Typography>

            <div>
              <IconButton
                color={!tabView ? 'primary' : 'default'}
                disabled={!tabView}
                onClick={() => {
                  setTabView(false);
                  setEditPath('');
                }}
              >
                {!tabView ? <List color="primary" /> : <List />}
              </IconButton>

              <IconButton
                color={tabView ? 'primary' : 'default'}
                disabled={tabView}
                onClick={() => {
                  setTabView(true);
                  setEditPath('');
                }}
              >
                {tabView ? <Folder color="primary" /> : <Folder />}
              </IconButton>
            </div>
          </div>
          {tabView ? (
            <OrgGroupsTabView
              groups={groupTree}
              openSettings={() => {
                setEditDialogState(true);
              }}
              setEditPath={setEditPath}
              setEditGroup={setEditGroup}
              ungroupedDevices={unGroupedDevices.data}
            />
          ) : (
            <>
              <OrgGroupsTable
                groupTree={groupTree}
                groups={groupTree}
                openSettings={() => {
                  setEditDialogState(true);
                }}
                setEditGroup={setEditGroup}
                devices={unGroupedDevices.data}
              />
            </>
          )}
        </div>
      </Paper>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '32px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setEditGroup(undefined);
            setEditDialogState(true);
          }}
        >
          Create New Group
        </Button>
      </div>

      <Dialog
        fullScreen
        open={editDialogState}
        onClose={() => {
          setEditDialogState(false);
          setEditGroup(undefined);
        }}
      >
        <OrgGroupsEditForm
          ungroupedDevices={unGroupedDevices.data || []}
          groupTree={groupTree}
          group={editGroup}
          editPath={editPath}
          closeDialog={() => {
            queryClient.refetchQueries('get-groups');
            queryClient.invalidateQueries('getUngroupedDevices');
            setEditDialogState(false);
          }}
        />
      </Dialog>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
