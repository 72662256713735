import { Button, Modal, Paper, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

type props = {
  open: boolean;
  onClose: Function;
  onConfirm: Function;
  title: string;
  message?: string;
  confirmText: string;
  children?: ReactNode;
};

export default function ConfirmationModal({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
  children,
}: props) {
  const theme = useTheme();

  return (
    <Modal
      sx={{ margin: theme.spacing(2) }}
      open={open}
      onClose={() => onClose()}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '20vh auto',
          maxWidth: '400px',
          gap: '10px',
        }}
      >
        <Typography variant="h2">{title}</Typography>
        {message ?? <Typography>{message}</Typography>}
        {children}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(1),
          }}
        >
          <Button
            variant="text"
            color="secondary"
            style={{ marginRight: '16px', color: '#818387' }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#F0142F', color: '#ffffff' }}
            onClick={() => onConfirm()}
          >
            {confirmText}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
