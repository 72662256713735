import { Typography } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../context/UserStateManager';

function UserProfileMenu() {
  const userCon = useContext(UserContext);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          height: '42px',
          width: '42px',
          backgroundColor: '#57B8FF',
          borderRadius: '42px',
        }}
      >
        <div
          style={{
            margin: '10px auto',
            width: 'fit-content',
            color: '#ffffff',
            fontSize: '16px',
            fontWeight: 700,
          }}
        >
          {userCon.state.user.firstName?.charAt(0) +
            userCon.state.user.lastName?.charAt(0)}
        </div>
      </div>
      <div style={{ width: '16px' }} />
      <Typography>
        {userCon.state.user.firstName + ' ' + userCon.state.user.lastName}
      </Typography>
    </div>
  );
}

export default UserProfileMenu;
