import {
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useRef, useState } from 'react';
import OrgForm from './OrgForm';
import ConfirmationModal from '../common/ConfirmationModal';
import { UserContext } from '../context/UserStateManager';
import { SysRole } from '../Enums';
import { OrgModel } from './orgTypes';

type AppProps = {
  orgs: OrgModel[];
  admin?: boolean;
  saveOrg: Function;
  updateOrg: Function;
  deleteOrg: Function;
};

function OrgList({ orgs, saveOrg, updateOrg, deleteOrg }: AppProps) {
  const userCon = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [formState, setFormState] = useState<{
    open: boolean;
    type: null | Function;
    org: null | OrgModel;
  }>({ open: false, type: null, org: null });
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: '',
      headerName: '',
      width: 40,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setFormState({ ...formState, org: params.row as OrgModel });
          }}
          style={{ padding: 0 }}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: window.innerWidth < 960 ? 250 : 500,
    },
  ];

  return (
    <Paper className="AdminContentContainer">
      <Typography variant="h2">Organizations</Typography>
      <div className="AdminTableContainer">
        <DataGrid
          autoPageSize
          density="compact"
          rows={orgs}
          columns={columns}
          getRowId={(row) => row._id}
          ref={(n) => {
            tableRef.current = n;
          }}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={(e) => {
            setFormState({ open: true, type: updateOrg, org: formState.org });
            setAnchorEl(null);
          }}
        >
          <Typography>Edit</Typography>
        </MenuItem>
        {userCon.state.user.role === SysRole.SYSTEM_ADMIN && (
          <MenuItem
            color="error"
            onClick={() => {
              setConfirmModalOpen(true);
              setAnchorEl(null);
            }}
          >
            <Typography color="error">Delete</Typography>
          </MenuItem>
        )}
      </Menu>
      <div
        style={{
          padding: '16px 0',
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => setFormState({ open: true, type: saveOrg, org: null })}
        >
          Add Organization
        </Button>
      </div>

      <ConfirmationModal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => {
          setConfirmModalOpen(false);
          deleteOrg(formState.org);
        }}
        title="Delete Organization?"
        message="Are you sure you want to delete this organization?"
        confirmText="Delete"
      />

      <Dialog
        fullScreen
        open={formState.open}
        onClose={() => setFormState({ open: false, type: null, org: null })}
      >
        {formState.type !== null && (
          <OrgForm
            orgData={formState.org}
            closeDialog={() =>
              setFormState({ open: false, type: null, org: null })
            }
            submitOrg={formState.type}
          />
        )}
      </Dialog>
    </Paper>
  );
}

export default OrgList;
