// unstable import for this issue: https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useContext } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Navigation from './components/navigation/Navigation';
import LoginPage from './components/login/LoginPage';
import { UserContext } from './components/context/UserStateManager';
import Admin from './components/admin/Admin';
import SetPassword from './components/login/SetPassword';
import NewUser from './components/login/NewUser';
import ForgotPasswordPage from './components/login/ForgotPasswordPage';
import OrgSettings from './components/orgs/OrgSettings';
import { OrgRole, SysRole } from './components/Enums';
import Devices from './components/devices/Devices';
import './globalStyles.css';
import UserProfile from './components/users/UserProfile';
import { QueryClient, QueryClientProvider } from 'react-query';
import RegisterController from './components/devices/RegisterController';
import { OrgColorContextProvider } from './components/context/OrgContext';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const theme = createTheme({
  typography: {
    fontFamily: 'Nunito Sans',
    fontSize: 14,
  },
  palette: {
    primary: { main: '#0380BA' },
    secondary: { main: '#ffffff' },
    background: { default: '#f4f7fc', paper: '#ffffff' },
    text: { primary: '#171725' },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
        },
        labelPlacementStart: {
          color: '#5e5d5d',
          marginLeft: '0',
          marginRight: '0',
          margin: '8px 0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
        elevation1: {
          padding: 16,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        // The default props to change
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        // The default props to change
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        // The default props to change
        variant: 'standard',
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(3, 128, 186, 0.1)',
          },
        },
      },
    },
  },
});
theme.typography.h1 = {
  fontWeight: 800,
  fontSize: '24px',
  [theme.breakpoints.up('md')]: {
    fontSize: '28px',
  },
};
theme.typography.h2 = {
  fontWeight: 400,
  fontSize: '20px',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
  },
};
theme.typography.h3 = {
  fontWeight: 600,
  fontSize: '16px',
};
theme.typography.h4 = {
  fontWeight: 400,
  fontSize: '14px',
};

const queryClient = new QueryClient();

function App() {
  const userCon = useContext(UserContext);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Route
                exact
                path={['/login', '/login/:orgId']}
                component={LoginPage}
              />
              <Route
                exact
                path="/forgotpassword"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/password/:email/:token"
                component={() => SetPassword(false)}
              />
              <Route
                exact
                path="/invite/:email/:token"
                component={() => SetPassword(true)}
              />
              <Route exact path="/newuser" component={() => NewUser()} />
              <Route>
                {userCon.state.jwtToken !== '' ? (
                  <div style={{ display: 'flex', overflowX: 'hidden' }}>
                    <Navigation />
                    <main
                      style={{
                        flexGrow: 1,
                        padding: theme.spacing(3),
                        maxWidth: `calc(100% - ${
                          parseInt(theme.spacing(3)) * 2
                        }px)`,
                      }}
                    >
                      <div style={theme.mixins.toolbar} />
                      <Switch>
                        <OrgColorContextProvider>
                          <Route path="/admin">
                            {userCon.state.user.role === SysRole.SYSTEM_USER ? (
                              <Redirect to={'/controller'} />
                            ) : (
                              <Admin />
                            )}
                          </Route>
                          <Route path="/controller" component={Devices} />
                          <Route
                            path="/register"
                            component={RegisterController}
                          />

                          <Route path="/test/test" component={LoginPage} />

                          <Route path="/organization">
                            {userCon.state.user.role !== SysRole.SYSTEM_USER ||
                            userCon.state.currentOrg?.role ===
                              OrgRole.ORG_ADMIN ? (
                              <OrgSettings />
                            ) : (
                              <Redirect to={'/controller'} />
                            )}
                          </Route>
                          <Route path="/user" component={UserProfile} />
                          <Redirect to={'/controller'} />
                        </OrgColorContextProvider>
                      </Switch>
                    </main>
                  </div>
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
            </Switch>
          </Router>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
