import {
  AppBar,
  Button,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { UserContext } from '../context/UserStateManager';
import {
  defaultMusicShowConfiguration,
  MusicShowConfiguration,
} from '../devices/DeviceSettingsObject';
import { fetchStatusJson } from './DeviceStatusPage';
import MusicShowForm, { trimShowFields } from './MusicShowForm';

type props = {
  closeDialog: () => void;
  showData: null | MusicShowConfiguration;
  submitShow: (show: MusicShowConfiguration) => void;
};

export default function MusicShowDialog({
  closeDialog,
  showData,
  submitShow,
}: props) {
  const theme = useTheme();
  const history = useHistory();
  const userCon = useContext(UserContext);
  const { control, handleSubmit, getValues } = useForm<MusicShowConfiguration>({
    defaultValues: showData ?? { ...defaultMusicShowConfiguration },
  });
  const [outsideEvent, setOutsideEvent] = useState<null | MouseEvent>(null);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const listener = useRef<NodeJS.Timeout | null>(null);
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [previewActive, setPreviewActive] = useState(false);

  const handleFetchStatus = useCallback(() => {
    fetchStatusJson(userCon)
      .then((json: any) => {
        setDeviceConnected(true);
        setPreviewActive(json.previewActive ?? false);
        if (json.previewActive) {
          if (!listener.current)
            listener.current = setInterval(handleFetchStatus, 1000);
        } else {
          if (listener.current) {
            clearInterval(listener.current);
            listener.current = null;
          }
        }
      })
      .catch((e) => {
        setDeviceConnected(false);
        if (listener.current) {
          clearInterval(listener.current);
          listener.current = null;
          setPreviewActive(false);
        }
        console.error('Unable to connect to controller.');
      });
  }, [userCon]);

  useEffect(() => {
    handleFetchStatus();
    return () => {
      if (listener.current) {
        clearInterval(listener.current);
        listener.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // push current history so pop doesn't go back a page
    history.push(history.location);
    // close dialog on back button, since we pushed this goes back to the same page
    return history.listen((_, action) => {
      if (action === 'POP') {
        closeDialog();
      }
    });
  }, []);

  const previewShowButtonClick = useCallback(() => {
    const cur = getValues();
    trimShowFields(cur, true);
    (cur as any).timeout = 30;
    fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL +
        '/devices/preview/show/' +
        userCon.state.currentDevice?._id,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        body: JSON.stringify(cur),
      },
    ).then((resp) => {
      if (resp.ok) {
        setPreviewActive(true);
        if (!listener.current)
          listener.current = setInterval(handleFetchStatus, 1000);
      } else setDeviceConnected(false);
    });
  }, [
    getValues,
    handleFetchStatus,
    userCon.state.currentDevice?._id,
    userCon.state.jwtToken,
  ]);

  const stopPreviewShowButtonClick = useCallback(() => {
    fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL +
        '/devices/preview/stop/' +
        userCon.state.currentDevice?._id,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
      },
    ).then((resp) => {
      if (resp.ok) {
        setPreviewActive(false);
        if (listener.current) {
          clearInterval(listener.current);
          listener.current = null;
        }
      } else setDeviceConnected(false);
    });
  }, [userCon.state.currentDevice?._id, userCon.state.jwtToken]);

  return (
    <div
      style={{ minHeight: '100%', backgroundColor: '#F4F7FC' }}
      onClick={(e) => setOutsideEvent(e)}
    >
      <AppBar
        color="secondary"
        sx={{ height: `68px`, borderBottom: '1px solid #e0e0e0' }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {showData ? 'Edit Show' : 'Add Show'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: '68px' }} />
      <div
        style={{
          margin: 'auto',
          marginTop: theme.spacing(2),
          maxWidth: `min(400px, calc(100% - ${
            parseInt(theme.spacing(2)) * 2
          }px))`,
        }}
      >
        <Paper>
          <form
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            onSubmit={(e) => {
              e.stopPropagation();
              if (blockSubmit) e.preventDefault();
              else
                handleSubmit((show) => {
                  trimShowFields(show);
                  submitShow(show);
                  closeDialog();
                })(e);
            }}
          >
            <MusicShowForm<MusicShowConfiguration>
              control={control}
              setBlockSubmit={setBlockSubmit}
              outsideEvent={outsideEvent}
              basePath=""
              showData={showData}
            />
            <div style={{ display: 'flex', marginTop: '8px' }}>
              {previewActive && deviceConnected ? (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 'auto' }}
                  onClick={stopPreviewShowButtonClick}
                >
                  Stop Preview
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 'auto' }}
                  onClick={previewShowButtonClick}
                  disabled={!deviceConnected}
                >
                  Preview Show
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginLeft: 'auto' }}
              >
                Submit
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
}
