import { Typography } from '@mui/material';
import { useState } from 'react';

export default function JSONValidator({
  onChange,
  customValue,
  setError,
  clearError,
}: {
  onChange: any;
  customValue: JSON | [JSON];
  setError: Function;
  clearError: Function;
}) {
  const [jsonValue, setJsonValue] = useState(JSON.stringify(customValue));
  const [isValidJson, setIsValidJson] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setJsonValue(value);

    try {
      const customVal = JSON.parse(value);
      if (Object.keys(customVal).length === 0) {
        setIsValidJson(false);
      } else {
        setIsValidJson(true);
        clearError('customJson');
      }
      onChange(customVal);
    } catch (e) {
      //empty json is valid
      if (!value) {
        setIsValidJson(true);
        clearError('customJson');
        onChange(undefined);
      } else {
        setIsValidJson(false);
        setError('customJson', { type: 'manual', message: 'Invalid JSON' });
      }
    }
  };

  return (
    <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Typography variant="h3">Custom JSON Input:</Typography>
        {!isValidJson && (
          <Typography variant="h3" style={{ color: 'red', marginLeft: '20px' }}>
            Invalid JSON
          </Typography>
        )}
      </div>
      <textarea
        id="jsonInput"
        value={jsonValue}
        onChange={handleChange}
        placeholder="Enter JSON here..."
        style={{
          resize: 'none',
          minHeight: '100px',
          borderColor: isValidJson ? 'black' : 'red',
          borderRadius: '5px',
          padding: '5px',
        }}
      />
    </div>
  );
}
