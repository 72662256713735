import {
  Divider,
  FormControlLabel,
  Paper,
  Switch,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useContext } from 'react';
import { UserContext } from '../context/UserStateManager';
import { ShowConfiguration } from '../devices/DeviceSettingsObject';
import { DeviceSettingsContext } from './DevicePage';
import ShowLineupList from './ShowLineupList';
import OrgLineupOverrideBanner from './OrgLineupOverrideBanner';
import {
  DEFAULT_DEVICE_TYPE_WORDS_NORMAL,
  DEFAULT_DEVICE_TYPE_WORDS_PRIMARY,
  DEFAULT_DEVICE_TYPE_WORDS_SECONDARY,
  DEVICE_TYPE_CHECK,
  OTHER_DEVICE_TYPE_WORDS_NORMAL,
  OTHER_DEVICE_TYPE_WORDS_PRIMARY,
  OTHER_DEVICE_TYPE_WORDS_SECONDARY,
} from '../../constants';
import { OrgRole } from '../Enums';
import { useQuery } from 'react-query';
import { getCurrentShow } from './helpers';
import { fetchStatusJson } from './DeviceStatusPage';
import { getGroupById } from '../orgs/groupsHelpers';
import { ShowLineup } from '../admin/SystemLineupList';

export function MoodLighting({
  setConfiguration,
}: {
  setConfiguration: Function;
}) {
  const theme = useTheme();
  const userCon = useContext(UserContext);
  const { configuration, updating } = useContext(DeviceSettingsContext);

  const { data: currentLineupData, isLoading: isCurrentLineupLoading } =
    useQuery(['get-current-device-lineup', userCon.state.currentDevice], () =>
      getCurrentShow(userCon),
    );

  const { data: currentShowData, isLoading: isCurrentShowLoading } = useQuery(
    ['get-current-device-show', userCon.state.currentDevice],
    () => fetchStatusJson(userCon),
  );

  const currentDeviceGroup = getGroupById(
    userCon.state.currentDevice?.group || '',
    userCon.state.currentOrgGroupTree || [],
  );

  return (
    <div>
      <OrgLineupOverrideBanner
        showBanner={
          !configuration.ignoreLineupOverride &&
          currentLineupData?.label?.includes('Lineup')
        }
      >
        <div style={{ marginTop: '10px' }}>
          <InfoOutlined
            style={{
              marginRight: '4px',
              fontSize: '18px',
              verticalAlign: 'middle',
              paddingBottom: '2px',
            }}
          />
          Settings will remain disabled until the sync is removed or ignored.
        </div>

        <div style={{ marginTop: '14px' }}>
          <Typography variant="h3">Current Show</Typography>
          {isCurrentLineupLoading || isCurrentShowLoading ? (
            <CircularProgress size={'1rem'} />
          ) : (
            <>
              {currentLineupData &&
                (currentLineupData === 'Show' ? (
                  <>
                    Lineup:{' '}
                    {configuration.controllerType === DEVICE_TYPE_CHECK
                      ? DEFAULT_DEVICE_TYPE_WORDS_NORMAL
                      : OTHER_DEVICE_TYPE_WORDS_NORMAL}
                  </>
                ) : (
                  <>
                    {currentLineupData.label +
                      ': ' +
                      (currentLineupData?.label?.includes('Event')
                        ? currentLineupData.lineup.name
                        : currentLineupData.lineup
                            .map((ele: ShowLineup) => {
                              return ele.name;
                            })
                            .join(', '))}
                  </>
                ))}

              <Typography>
                Show:{' '}
                {currentShowData
                  ? currentShowData.currentShow
                  : 'Not connected'}
              </Typography>
            </>
          )}
        </div>

        {userCon.state.currentOrg?.role === OrgRole.ORG_ADMIN && (
          <>
            <Divider
              sx={{ root: { marginTop: '8px', backgroundColor: '#707070' } }}
            />
            <FormControlLabel
              label="Ignore Sync"
              labelPlacement="start"
              sx={{
                justifyContent: 'center',
                margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
              }}
              control={
                <Switch
                  disabled={updating}
                  checked={configuration.ignoreLineupOverride}
                  color="primary"
                  onChange={(e: any) => {
                    setConfiguration({
                      ...configuration,
                      ignoreLineupOverride: e.target.checked,
                    });
                  }}
                />
              }
            />
          </>
        )}
      </OrgLineupOverrideBanner>
      {(configuration.ignoreLineupOverride ||
        currentLineupData?.label?.includes('Event') ||
        currentLineupData === 'Show') && (
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h3">Current Show</Typography>
          {isCurrentLineupLoading || isCurrentShowLoading ? (
            <CircularProgress size={'1rem'} />
          ) : (
            <>
              {currentLineupData &&
                (currentLineupData === 'Show' ? (
                  <>
                    Lineup:{' '}
                    {configuration.controllerType === DEVICE_TYPE_CHECK
                      ? DEFAULT_DEVICE_TYPE_WORDS_NORMAL
                      : OTHER_DEVICE_TYPE_WORDS_NORMAL}
                  </>
                ) : (
                  <>
                    {currentLineupData.label +
                      ': ' +
                      (currentLineupData?.label?.includes('Event')
                        ? currentLineupData.lineup.name
                        : currentLineupData.lineup
                            .map((ele: ShowLineup) => {
                              return ele.name;
                            })
                            .join(', '))}
                  </>
                ))}

              <Typography>
                Show:{' '}
                {currentShowData
                  ? currentShowData.currentShow
                  : 'Not connected'}
              </Typography>
            </>
          )}
        </Paper>
      )}
      <Accordion>
        <AccordionSummary>
          {configuration.controllerType == DEVICE_TYPE_CHECK
            ? DEFAULT_DEVICE_TYPE_WORDS_PRIMARY
            : OTHER_DEVICE_TYPE_WORDS_PRIMARY}
        </AccordionSummary>
        <AccordionDetails style={{ display: 'block' }}>
          <ShowLineupList
            showList={configuration.showLineup}
            setShows={(showLineup: ShowConfiguration[]) =>
              setConfiguration({ ...configuration, showLineup })
            }
            zone={configuration.moodLightingZone ?? 0}
            setZone={(zoneNum) =>
              setConfiguration({ ...configuration, moodLightingZone: zoneNum })
            }
            disabled={
              (!!currentDeviceGroup?.groupLineupOverride &&
                !configuration.ignoreLineupOverride) ||
              updating ||
              userCon.state.currentOrg?.role === OrgRole.ORG_VIEWER
            }
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          {configuration.controllerType == DEVICE_TYPE_CHECK
            ? DEFAULT_DEVICE_TYPE_WORDS_SECONDARY
            : OTHER_DEVICE_TYPE_WORDS_SECONDARY}
        </AccordionSummary>

        <AccordionDetails style={{ display: 'block' }}>
          <ShowLineupList
            showList={configuration.secondaryShowLineup}
            setShows={(secondaryShowLineup: ShowConfiguration[]) =>
              setConfiguration({ ...configuration, secondaryShowLineup })
            }
            zone={configuration.secondaryMoodLightingZone ?? 0}
            setZone={(zoneNum) =>
              setConfiguration({
                ...configuration,
                secondaryMoodLightingZone: zoneNum,
              })
            }
            disabled={
              !configuration.ignoreLineupOverride ||
              updating ||
              userCon.state.currentOrg?.role === OrgRole.ORG_VIEWER
            }
          />
        </AccordionDetails>
      </Accordion>
      {(configuration.ignoreLineupOverride ||
        currentLineupData?.label?.includes('Event') ||
        currentLineupData === 'Show') && (
        <Paper style={{ marginBottom: '10px' }}>
          <Typography variant="h3">Group Lineup Sync</Typography>
          <Typography variant="h4">
            Organization managers may choose to sync{' '}
            {DEFAULT_DEVICE_TYPE_WORDS_NORMAL.toLocaleLowerCase()} lineups for
            all devices within a group. If necessary, the sync may be ignored.
          </Typography>
          {userCon.state.currentOrg?.role === OrgRole.ORG_ADMIN && (
            <>
              <Divider
                sx={{ root: { marginTop: '8px', backgroundColor: '#707070' } }}
              />
              <FormControlLabel
                label="Ignore Sync"
                labelPlacement="start"
                sx={{
                  justifyContent: 'center',
                  margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
                }}
                control={
                  <Switch
                    disabled={updating}
                    checked={configuration.ignoreLineupOverride}
                    color="primary"
                    onChange={(e: any) => {
                      setConfiguration({
                        ...configuration,
                        ignoreLineupOverride: e.target.checked,
                      });
                    }}
                  />
                }
              />
            </>
          )}
        </Paper>
      )}
    </div>
  );
}
