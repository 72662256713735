import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DurationComponent } from './DurationComponent';

type UniverseFormValues = {
  universeA: string;
  universeB: string;
  universeC: string;
  duration: number;
};

export function UniverseVariant({
  makeRequest,
  on,
  stopTrouble,
  error,
}: {
  makeRequest: (pixels: number[][], duration: number) => Promise<void>;
  on: boolean;
  stopTrouble: () => void;
  error: string;
}) {
  const colors = ['Red', 'Green', 'Blue', 'White', 'Off'];
  const universes = ['A', 'B', 'C'];
  const { control, handleSubmit } = useForm<UniverseFormValues>();
  const matches = useMediaQuery('(min-width: 900px)');

  const onSubmit = (data: UniverseFormValues) => {
    const pixelsToSendBack: number[][] = [];
    const universeValues = [data.universeA, data.universeB, data.universeC];

    universeValues.forEach((universe, i) => {
      const index = colors.indexOf(universe?.toString());
      if (index !== -1) {
        const myArray = Array.from({ length: 128 }, (_, j) => j + i * 128 + 1);
        pixelsToSendBack[index] = (pixelsToSendBack[index] || []).concat(
          myArray,
        );
      }
    });

    colors.forEach((_, index) => {
      if (!pixelsToSendBack[index]) {
        pixelsToSendBack[index] = [0];
      }
    });

    makeRequest(pixelsToSendBack, data.duration);
  };

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: '30px' }}>
        Choose colors for each universe and specify a duration.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {universes.map((universe) => {
            return (
              <div>
                <Controller
                  key={universe}
                  name={`universe${universe}` as keyof UniverseFormValues}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControl>
                        <FormLabel id={`universe${universe}`}>
                          Universe {universe}
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby={`universe${universe}`}
                          onChange={(_, newValue) => {
                            onChange(newValue);
                          }}
                          value={value}
                        >
                          {colors.map((color) => {
                            return (
                              <FormControlLabel
                                key={color}
                                value={color}
                                control={<Radio color="default" />}
                                label={color}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    );
                  }}
                />
              </div>
            );
          })}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            alignItems: 'center',
            flexDirection: matches ? 'row' : 'column',
          }}
        >
          <DurationComponent control={control} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={on || error.length !== 0}
            style={{ marginTop: matches ? '' : '10px' }}
          >
            Submit
          </Button>
        </div>
      </form>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {on && (
          <Button onClick={stopTrouble} variant="outlined" color="primary">
            Abort Troubleshoot
          </Button>
        )}
      </div>
    </>
  );
}
