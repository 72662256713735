import {
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { refreshUserContext, UserContext } from '../context/UserStateManager';
import ConfirmationModal from '../common/ConfirmationModal';
import { MoreVert } from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import { GroupShowLineup } from './orgTypes';
import {
  addOrgLineup,
  deleteOrgLineup,
  updateLineupOverride,
  updateOrgLineup,
} from './helpers';
import { toast } from 'react-toastify';
import GroupEditLineupForm from './GroupEditLineupForm';
import {
  checkGroupLineupOverride,
  checkMultipleOverride,
  findParentGroupIdByPath,
  getGroupNameById,
} from './groupsHelpers';
import {
  DEFAULT_DEVICE_TYPE_WORDS_PRIMARY,
  DEFAULT_DEVICE_TYPE_WORDS_SECONDARY,
} from '../../constants';

export default function GroupLineupList() {
  const userCon = useContext(UserContext);
  const targetId = useRef('');
  const [currentTab, setCurrentTab] = useState(0);

  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState({
    open: false,
    showLineup: {} as GroupShowLineup | null,
    id: '',
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentId, setCurrentId] = useState('');

  const queryClient = useQueryClient();

  const formDetailsObject = {
    title: 'Group Lineups',
    formTitle: 'Group',
    description:
      'Applying a sync will cause all Devices in this Group to' +
      ' use the selected Lineup, unless they have the "Ignore Sync" option' +
      ' enabled. Sync may take up to five minutes to take effect.',
    promptMessage: 'Remove Group Lineup',
  };

  const { mutateAsync: createOrgLineupMutation } = useMutation(
    (lineup: GroupShowLineup) =>
      addOrgLineup(userCon, lineup, currentTab === 0 ? 'primary' : 'secondary'),
    {
      onSuccess: () => {
        refreshUserContext(userCon.state, userCon.setState);
        toast.success('Created Lineup!');
      },
    },
  );

  const { mutateAsync: updateOrgLineupMutation } = useMutation(
    (lineup: GroupShowLineup) =>
      updateOrgLineup(
        userCon,
        lineup,
        currentTab === 0 ? 'primary' : 'secondary',
      ),
    {
      onSuccess: () => {
        refreshUserContext(userCon.state, userCon.setState);
        toast.success('Updated Lineup!');
      },
    },
  );

  const { mutateAsync: deleteOrgLineupMutation } = useMutation(
    (lineupId: string) =>
      deleteOrgLineup(
        userCon,
        lineupId,
        currentTab === 0 ? 'primary' : 'secondary',
      ),
    {
      onSuccess: () => {
        refreshUserContext(userCon.state, userCon.setState);
        setRemoveModalOpen(false);
        toast.success('Deleted Lineup!');
      },
    },
  );

  const { mutateAsync: updateGroupLineupOverride } = useMutation(
    async (variables: { lineupId: string; groups: string[] }) =>
      await updateLineupOverride(
        userCon,
        variables.lineupId,
        variables.groups,
        currentTab === 0 ? 'primary' : 'secondary',
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get-groups');
        toast.success('Updated Lineup Sync!');
      },
    },
  );
  const matches = useMediaQuery('(min-width: 500px)');

  return (
    <div>
      <Paper>
        <Typography variant="h3">{formDetailsObject.title}</Typography>
        <Typography>{formDetailsObject.description}</Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '16px',
          }}
        >
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={currentTab}
            onChange={(e, val) => {
              setCurrentTab(val);
            }}
            aria-label="basic tabs example"
          >
            <Tab label={DEFAULT_DEVICE_TYPE_WORDS_PRIMARY} />
            <Tab label={DEFAULT_DEVICE_TYPE_WORDS_SECONDARY} />
          </Tabs>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell style={{ display: matches ? 'table-cell' : 'none' }}>
                Groups
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(currentTab === 0
              ? userCon.state.currentOrg?.org.orgLineups || []
              : userCon.state.currentOrg?.org.secondaryOrgLineups || []
            ).map((lineup) => (
              <TableRow key={lineup._id}>
                <TableCell width={1}>
                  <IconButton
                    disabled={checkGroupLineupOverride(
                      userCon.state.currentOrgGroupTree || [],
                      lineup._id,
                      currentTab === 0 ? 'primary' : 'secondary',
                    )}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      setCurrentId(lineup._id);
                      setAnchorEl(e.currentTarget);
                    }}
                    style={{ padding: 0 }}
                  >
                    <MoreVert />
                  </IconButton>
                </TableCell>
                <TableCell style={{ padding: '12px' }}>{lineup.name}</TableCell>{' '}
                <TableCell style={{ display: matches ? 'table-cell' : 'none' }}>
                  {lineup.groups.map(
                    (group, index) =>
                      (
                        (userCon.state.currentOrgGroupPaths || []).find((ele) =>
                          ele.endsWith(
                            getGroupNameById(
                              group,
                              userCon.state.currentOrgGroupTree || [],
                            ) || '',
                          ),
                        ) || ''
                      )
                        .split('/')
                        .join(' / ') +
                      (index + 1 !== lineup.groups.length ? ', ' : ''),
                  )}
                </TableCell>
                <TableCell>
                  {checkGroupLineupOverride(
                    userCon.state.currentOrgGroupTree || [],
                    lineup._id,
                    currentTab === 0 ? 'primary' : 'secondary',
                  ) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: '#4c8c1a',
                        color: '#ffffff',
                      }}
                      onClick={() => {
                        updateGroupLineupOverride({
                          lineupId: 'remove',
                          groups: lineup.groups,
                        });
                      }}
                    >
                      Stop Lineup Sync
                    </Button>
                  ) : (
                    <Tooltip
                      disableHoverListener={
                        lineup.groups &&
                        lineup.groups.length > 0 &&
                        !checkMultipleOverride(
                          userCon.state.currentOrgGroupTree || [],
                          lineup,
                          currentTab === 0 ? 'primary' : 'secondary',
                        )
                      }
                      title={
                        checkMultipleOverride(
                          userCon.state.currentOrgGroupTree || [],
                          lineup,
                          currentTab === 0 ? 'primary' : 'secondary',
                        )
                          ? 'Another lineup is already syncing to a group in this lineup.'
                          : 'Need to add a group to lineup!'
                      }
                    >
                      <span>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={
                            !lineup.groups ||
                            lineup.groups.length <= 0 ||
                            checkMultipleOverride(
                              userCon.state.currentOrgGroupTree || [],
                              lineup,
                              currentTab === 0 ? 'primary' : 'secondary',
                            )
                          }
                          onClick={() => {
                            updateGroupLineupOverride({
                              lineupId: lineup._id,
                              groups: lineup.groups,
                            });
                          }}
                        >
                          Sync Lineup to Groups
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              targetId.current = currentId;
              setEditFormOpen({
                showLineup:
                  (
                    (currentTab === 0
                      ? userCon.state.currentOrg?.org.orgLineups || []
                      : userCon.state.currentOrg?.org.secondaryOrgLineups ||
                        []) || []
                  ).find(({ _id }) => _id === targetId.current) || null,
                open: true,
                id: currentId,
              });
            }}
          >
            <Typography>Edit</Typography>
          </MenuItem>
          <MenuItem
            color="error"
            onClick={() => {
              setAnchorEl(null);
              targetId.current = currentId;
              setRemoveModalOpen(true);
            }}
          >
            <Typography color="error">Delete</Typography>
          </MenuItem>
        </Menu>
        <div
          style={{
            padding: '16px 0',
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setEditFormOpen({
                ...editFormOpen,
                showLineup: null,
                open: true,
              })
            }
            style={{ margin: '32px auto' }}
          >
            Add Lineup
          </Button>
        </div>
      </Paper>
      <ConfirmationModal
        open={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        onConfirm={() => {
          deleteOrgLineupMutation(targetId.current);
        }}
        title={formDetailsObject.promptMessage}
        message="Are you sure you want to remove this lineup?"
        confirmText="Remove"
      />
      <Dialog
        fullScreen
        open={editFormOpen.open}
        onClose={() =>
          setEditFormOpen({ showLineup: null, open: false, id: '' })
        }
      >
        <GroupEditLineupForm
          title={formDetailsObject.formTitle}
          closeDialog={() => {
            setEditFormOpen({ showLineup: null, open: false, id: '' });
          }}
          showLineup={editFormOpen.showLineup}
          submit={async (lineup) => {
            const sendLineup = {
              ...lineup,
              groups:
                lineup.groups &&
                lineup.groups.map(
                  (ele) =>
                    findParentGroupIdByPath(
                      ele,
                      userCon.state.currentOrgGroupTree ?? [],
                    ) || '',
                ),
            };
            try {
              if (editFormOpen.showLineup) {
                await updateOrgLineupMutation(sendLineup);
              } else {
                await createOrgLineupMutation(sendLineup);
              }
              return { success: true };
            } catch (e: any) {
              return { success: false, message: e.message };
            }
          }}
        />
      </Dialog>
    </div>
  );
}
