import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useState, useContext, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import colorwashLogo from '../../assets/images/color-wash-logo-vertical.png';
import { refreshUserContext, UserContext } from '../context/UserStateManager';
import StatusCodeResponse from '../common/StatusCodeResponse';
import useTitle from '../../hooks/useTitle.hook';

type tUserLogin = { email: string; password: string };

export const LoginPage = () => {
  const userCon = useContext(UserContext);
  const history = useHistory();
  const { orgId } = useParams<{ orgId?: string }>();
  const theme = useTheme();
  const [rememberMe, setRememberMe] = useState(
    !!window.localStorage.getItem('email'),
  );
  const { handleSubmit, control } = useForm<tUserLogin>();
  const [statusCode, setStatusCode] = useState(0);
  const formSubmitted = useRef(false);
  useTitle('Login');

  useEffect(() => {
    // go to controller once user logged in
    if (userCon.state.jwtToken.length > 0 && formSubmitted.current)
      history.push('/controller');
  }, [history, userCon.state]);

  const submit = async (userLogin: tUserLogin) => {
    // e.preventDefault();
    // login and get JWT
    let resp = await fetch(process.env.REACT_APP_BACKEND_URL + '/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userLogin),
    });
    const { access_token } = await resp.json();

    setStatusCode(resp.status);

    // fail login here
    if (resp.status !== 201) return;

    formSubmitted.current = true;
    // get user info
    refreshUserContext(
      {
        ...userCon.state,
        jwtToken: access_token,
      },
      userCon.setState,
      orgId,
    );

    if (rememberMe) {
      window.localStorage.setItem('email', userLogin.email);
    } else {
      window.localStorage.removeItem('email');
    }
  };

  return (
    <div
      style={{
        margin: 'calc(33vh - 190px) auto',
        width: '312px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img style={{ width: '312px' }} src={colorwashLogo} alt="Colorwash" />
      <Paper
        sx={{
          marginTop: theme.spacing(4),
          '& form': {
            display: 'flex',
            height: '275px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '& button': {
              margin: '0 auto',
              width: 'fit-content',
            },
            '& h1': {
              fontSize: '18px',
              fontWeight: 'bold',
            },
          },
        }}
      >
        <form onSubmit={handleSubmit((userLogin) => submit(userLogin))}>
          <Typography variant="h1">Log In</Typography>

          <Controller
            name="email"
            control={control}
            defaultValue={window.localStorage.getItem('email') ?? ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email Address"
                type="email"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="username"
                id="username"
              />
            )}
            rules={{
              required: 'Required',
              maxLength: {
                value: 320,
                message: 'Must be less than 320 characters',
              },
            }}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Password"
                type="password"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="current-password"
                id="current-password"
              />
            )}
            rules={{
              required: 'Required',
              maxLength: {
                value: 128,
                message: 'Must be less than 128 characters',
              },
            }}
          />

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#818387',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(!rememberMe)}
                />
              }
              label={<Typography variant="caption">Remember Me</Typography>}
            />
            <div style={{ textAlign: 'right' }}>
              <Link
                style={{ color: '#818387', textDecoration: 'none' }}
                to="/forgotpassword"
              >
                <Typography variant="caption">Reset Password</Typography>
              </Link>
              <br />
              <Link
                style={{ color: '#818387', textDecoration: 'none' }}
                to="/newuser"
              >
                <Typography variant="caption">Create New Account</Typography>
              </Link>
            </div>
          </div>

          <Button color="primary" type="submit" variant="contained">
            Log In
          </Button>
        </form>
        <StatusCodeResponse
          statusCode={statusCode}
          codeResponses={[
            {
              statusCode: 401,
              response: 'Invalid Credentials',
            },
            {
              statusCode: 403,
              response: 'Password Reset Required',
            },
            {
              statusCode: 405,
              response: 'Please contact Organization Administrator.',
            },
          ]}
        />
      </Paper>
    </div>
  );
};

export default LoginPage;
