import {
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useRef, useState } from 'react';
import { DeviceModel } from './Devices';
import DeviceForm from './DeviceForm';
import { fetchOrgInfo, UserContext } from '../context/UserStateManager';
import ConfirmationModal from '../common/ConfirmationModal';
import { Redirect } from 'react-router-dom';
import { deviceIsConnected } from './DeviceList';
import { LogType } from '../Enums';
import DeviceLog from './DeviceLog';
import { useQueryClient } from 'react-query';

type AppProps = {
  devices: DeviceModel[];
  getDevice: Function;
  updateDevice: Function;
  deleteDevice: Function;
};

export function AdminDeviceList({
  devices,
  getDevice,
  updateDevice,
  deleteDevice,
}: AppProps) {
  const userCon = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [formState, setFormState] = useState<{
    open: boolean;
    deviceObjID: null | string;
    logType: null | LogType;
  }>({ open: false, deviceObjID: null, logType: null });
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [redirect, setRedirect] = useState<string | null>(null);

  const matchDevice = (id: string) =>
    devices.find((device) => device._id === id) ?? null;

  const getCurrentDevice = () =>
    devices.filter((device) => device._id === formState.deviceObjID)[0] ?? null;

  const columns: GridColDef[] = [
    {
      field: '',
      headerName: '',
      width: 40,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setFormState({ ...formState, deviceObjID: params.id as string });
          }}
          style={{ padding: 0 }}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
    {
      field: 'deviceID',
      headerName: 'ID',
      width: 210,
      renderCell: (params: GridCellParams<DeviceModel>) => {
        const device = matchDevice(`${params.row._id}`); // Use `params.row._id`
        const org = userCon.state.user.orgs.find(
          ({ org }) => org._id === params.row.org, // Use `params.row.org`
        );

        if (!org) {
          return params.row.deviceID;
        }

        return (
          <div
            style={{ cursor: 'pointer', width: '100%' }}
            onClick={async () => {
              const [orgInfo, deviceInfo] = await Promise.all([
                fetchOrgInfo(userCon, org.org._id),
                getDevice(device?._id),
              ]);

              if (orgInfo && deviceInfo) {
                userCon.setState({
                  ...userCon.state,
                  currentDevice: deviceInfo,
                  currentOrg: org ? { org: orgInfo, role: org.role } : null,
                });

                setRedirect(device?._id || null);
              }
            }}
          >
            {params.row.deviceID}
          </div>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 210,
    },
    {
      field: 'org',
      headerName: 'Organization',
      width: 210,
      valueGetter: (params, row) => {
        let name = 'No Organization';
        for (let obj of userCon.state.user.orgs) {
          if (obj.org._id === row.org) {
            name = obj.org.name;
            break;
          }
        }
        return name;
      },
    },
    {
      field: 'deviceStatus',
      headerName: 'Status',
      width: 120,
      renderCell: (params: GridCellParams) => (
        <div
          style={{
            height: '20px',
            width: '20px',
            marginTop: '6px',
            borderRadius: '20px',
            backgroundColor: params.value ? '#11E300' : '#bebebe',
          }}
        />
      ),
    },
  ];

  if (redirect) return <Redirect to={`/controller/${redirect}`} />;

  return (
    <Paper className="AdminContentContainer">
      <Typography variant="h2">Controllers</Typography>
      <div className="AdminTableContainer">
        <DataGrid
          autoPageSize
          density="compact"
          rows={devices.map((device) => ({
            ...device,
            deviceStatus: deviceIsConnected(device.lastCheckin),
          }))}
          columns={columns}
          getRowId={(row) => row._id}
          ref={(n) => {
            tableRef.current = n;
          }}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setFormState({
              ...formState,
              open: true,
              logType: null,
            });
          }}
        >
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem
          color="error"
          onClick={() => {
            setConfirmModalOpen(true);
            setAnchorEl(null);
          }}
        >
          <Typography color="error">Delete</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setFormState({ ...formState, open: true, logType: LogType.STATUS });
          }}
        >
          <Typography>Status</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setFormState({
              ...formState,
              open: true,
              logType: LogType.SETTINGS,
            });
          }}
        >
          <Typography>Settings</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setFormState({ ...formState, open: true, logType: LogType.DEBUG });
          }}
        >
          <Typography>Debug</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setFormState({
              ...formState,
              open: true,
              logType: LogType.CCEVENT,
            });
          }}
        >
          <Typography>CC Event</Typography>
        </MenuItem>
      </Menu>

      <ConfirmationModal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => {
          setConfirmModalOpen(false);
          deleteDevice(getCurrentDevice()._id);
        }}
        title="Delete Controller?"
        message="Are you sure you want to delete this controller?"
        confirmText="Delete"
      />

      <Dialog
        fullScreen
        open={formState.open && !!formState.logType}
        onClose={() =>
          setFormState({ open: false, deviceObjID: null, logType: null })
        }
      >
        <DeviceLog
          deviceData={getCurrentDevice()}
          closeDialog={() =>
            setFormState({
              open: false,
              deviceObjID: null,
              logType: null,
            })
          }
          logType={formState.logType}
        />
      </Dialog>
      <Dialog
        fullScreen
        open={formState.open && !!!formState.logType}
        onClose={() =>
          setFormState({ open: false, deviceObjID: null, logType: null })
        }
      >
        <DeviceForm
          deviceData={getCurrentDevice()}
          submitDevice={updateDevice}
          closeDialog={() =>
            setFormState({ open: false, deviceObjID: null, logType: null })
          }
        />
      </Dialog>
    </Paper>
  );
}

export default AdminDeviceList;
