import { FormControlLabel, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

export function DurationComponent({ control }: { control: Control<any> }) {
  return (
    <>
      <Controller
        name="duration"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <FormControlLabel
              label="Duration (s):"
              labelPlacement="start"
              style={{ maxWidth: '400px' }}
              control={
                <TextField
                  type="number"
                  style={{ marginLeft: '7px' }}
                  color="primary"
                  onChange={(e) => {
                    const numericValue = parseInt(e.target.value, 10);
                    onChange(numericValue);
                  }}
                  value={value}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              }
            />
          );
        }}
        rules={{
          required: 'A duration is required',
          validate: (value) => {
            if (parseInt(value) <= 0) return 'Must be greater than 0';
            if (parseInt(value) > 3600) return 'Must be less than 3600';
          },
        }}
      />
    </>
  );
}
