import { useContext } from 'react';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { UserContext, getOrgsForAdmin } from '../context/UserStateManager';
import OrgList from './OrgList';
import { OrgModel } from './orgTypes';

function Orgs() {
  const userCon = useContext(UserContext);

  const saveOrg = async (org: OrgModel) => {
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/orgs',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        body: JSON.stringify(org),
      },
    );

    if (res.status === 201) {
      getOrgsForAdmin(userCon);
    }
    return res.status;
  };

  const deleteOrg = async (org: OrgModel) => {
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/orgs/' + org._id,
      {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
      },
    );

    if (res.status === 204) {
      getOrgsForAdmin(userCon);
    }
    return res.status;
  };

  const updateOrg = async ({ _id, name }: OrgModel) => {
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/orgs/' + _id,
      {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        body: JSON.stringify({ name }),
      },
    );

    if (res.status === 200) {
      getOrgsForAdmin(userCon);
    }
    return res.status;
  };

  return (
    <OrgList
      orgs={userCon.state.user.orgs.map((userOrg) => userOrg.org)}
      saveOrg={saveOrg}
      updateOrg={updateOrg}
      deleteOrg={deleteOrg}
    />
  );
}

export default Orgs;
