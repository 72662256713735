import { ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function ListItemLink(props: { primary: any; to: any; icon: any }) {
  const { primary, to, icon } = props;

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref as any} to={to} {...linkProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItemButton component={CustomLink}>
        {React.createElement(icon, { style: { fill: '#0380BA' } })}
        <div style={{ width: '16px' }} />
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
}

export default ListItemLink;
