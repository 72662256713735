import {
  Box,
  Button,
  Checkbox,
  Modal,
  Paper,
  Slider,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SunConditionItem } from '../devices/DeviceSettingsObject';
import { Brightness3, WbSunny } from '@mui/icons-material';

type props = {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
  disabled: boolean[];
  current?: SunConditionItem;
};

const defaultVals: SunConditionItem = {
  sunriseOffset: '0',
  sunsetOffset: '0',
  sunriseOnOrOff: true,
  sunsetOnOrOff: false,
  daysOfWeek: Array(7).fill(false),
};

const marks = [
  {
    value: -60,
    label: 'Before',
  },
  {
    value: 0,
    label: 'At',
  },
  {
    value: 60,
    label: 'After',
  },
];

export default function SunSelectModal({
  open,
  onClose,
  onSubmit,
  disabled,
  current,
}: props) {
  const {
    control,
    handleSubmit,
    formState,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm<SunConditionItem>({
    defaultValues: defaultVals,
    mode: 'onChange',
  });

  useEffect(() => {
    reset(current || defaultVals);
  }, [current, reset]);

  const handleTabChange = (event: ChangeEvent<{}>, newValue: any) => {
    if (newValue === 'sunrise') {
      setValue('sunriseOnOrOff', true);
      setValue('sunsetOnOrOff', false);
    } else {
      setValue('sunriseOnOrOff', false);
      setValue('sunsetOnOrOff', true);
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        clearErrors();
      }}
    >
      <Paper sx={{ margin: '20vh auto', width: 'fit-content' }}>
        <form
          style={{
            padding: '50px min(calc((100vw - 322px) / 2), 80px)',
            maxWidth: '500px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit((value) => {
              let non_selected = true;
              for (let bool of value.daysOfWeek) {
                if (bool) non_selected = false;
              }
              if (non_selected) {
                setError('daysOfWeek', { message: 'Select at least one day.' });
                return;
              }
              onSubmit({
                ...value,
              });
              setValue('sunriseOffset', String(defaultVals.sunriseOffset));
              setValue('sunsetOffset', String(defaultVals.sunsetOffset));
              setValue('sunriseOnOrOff', defaultVals.sunriseOnOrOff);
              setValue('sunsetOnOrOff', defaultVals.sunsetOnOrOff);
              setValue('daysOfWeek', defaultVals.daysOfWeek);
            })(e);
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                name="sunriseOnOrOff"
                control={control}
                render={({ field: { value: sunriseValue } }) => (
                  <Controller
                    name="sunsetOnOrOff"
                    control={control}
                    render={({ field: { value: sunsetValue } }) => (
                      <Tabs
                        value={sunriseValue ? 'sunrise' : 'sunset'}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab
                          label="Turn On At Sunrise"
                          value="sunrise"
                          icon={<WbSunny />}
                          style={{ marginRight: '10px' }}
                        />
                        <Tab
                          label="Turn On At Sunset"
                          icon={<Brightness3 />}
                          value="sunset"
                        />
                      </Tabs>
                    )}
                  />
                )}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Controller
                name="daysOfWeek"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div>
                    {[
                      'Sunday',
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                    ].map((day, i) => (
                      <Checkbox
                        key={i}
                        aria-label={'checkbox-' + day}
                        style={{ margin: '-4px' }}
                        icon={
                          <Box
                            sx={{
                              height: '36px',
                              width: '36px',
                              borderRadius: '50%', // Ensures the bubble is circular
                              fontSize: '16px',
                              fontWeight: 600,
                              backgroundColor: '#F2F2F3',
                              color: '#171725',
                              display: 'flex', // Use flexbox for centering
                              alignItems: 'center', // Center vertically
                              justifyContent: 'center', // Center horizontally
                              'input:disabled ~ &': {
                                backgroundColor: '#aaaaaa',
                              },
                            }}
                          >
                            <span>{day.slice(0, 1)}</span>
                          </Box>
                        }
                        checkedIcon={
                          <Box
                            sx={{
                              height: '36px',
                              width: '36px',
                              borderRadius: '50%', // Ensures the bubble is circular
                              fontSize: '18px',
                              fontWeight: 600,
                              backgroundColor: '#0380BA',
                              color: '#F4F7FC',
                              display: 'flex', // Use flexbox for centering
                              alignItems: 'center', // Center vertically
                              justifyContent: 'center', // Center horizontally
                              'input:disabled ~ &': {
                                backgroundColor: '#aaaaaa',
                              },
                            }}
                          >
                            <span>{day.slice(0, 1)}</span>
                          </Box>
                        }
                        value={i}
                        checked={value[i]}
                        onChange={(e) => {
                          let ar = [...value];
                          ar[i] = e.target.checked;
                          onChange(ar);
                        }}
                        disabled={disabled[i]}
                      />
                    ))}
                  </div>
                )}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around',
                  gap: '40px',
                }}
              >
                <div
                  style={{
                    width: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h4">Sunrise Offset (Minutes)</Typography>

                  <Controller
                    name="sunriseOffset"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Slider
                        min={-60}
                        max={60}
                        value={Number(value)}
                        onChange={(e, val) => onChange(Number(val))}
                        valueLabelDisplay="auto"
                        marks={marks}
                      />
                    )}
                    rules={{
                      maxLength: {
                        value: 4,
                        message: 'Must be less than 4 characters',
                      },
                    }}
                  />
                </div>

                <div
                  style={{
                    width: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h4">Sunset Offset (Minutes)</Typography>
                  <Controller
                    name="sunsetOffset"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Slider
                        min={-60}
                        max={60}
                        value={Number(value)}
                        onChange={(e, val) => onChange(Number(val))}
                        marks={marks}
                        valueLabelDisplay="auto"
                      />
                    )}
                    rules={{
                      maxLength: {
                        value: 4,
                        message: 'Must be less than 4 characters',
                      },
                    }}
                  />
                </div>
              </div>

              {Object.keys(formState.errors).length > 0 && (
                <div>
                  {Object.keys(formState.errors).map((error, i) => (
                    <Typography color="error" key={i}>
                      {(formState as any).errors[error].message}
                    </Typography>
                  ))}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Button
                sx={{ marginLeft: 'auto', marginRight: 'auto' }}
                color="primary"
                variant="outlined"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button
                sx={{ marginLeft: 'auto', marginRight: 'auto' }}
                color="primary"
                variant="contained"
                type="submit"
              >
                Done
              </Button>
            </div>
          </div>
        </form>
      </Paper>
    </Modal>
  );
}
/*


 
*/
