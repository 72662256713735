import { Icon, IconButton, Paper, Typography } from '@mui/material';
import { FolderOpen, Settings } from '@mui/icons-material';
import { GroupTreeNode } from '../orgTypes';

interface OrgGroupBoxProps {
  node: GroupTreeNode;
  openSettings: Function;
  setCurrentGroups: Function;
  setCurrentDevices?: Function;
}
export default function OrgGroupBox({
  node,
  openSettings,
  setCurrentGroups,
  setCurrentDevices,
}: OrgGroupBoxProps) {
  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '150px',
        height: '150px',
      }}
    >
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', height: '10%' }}
      >
        <IconButton onClick={() => openSettings()}>
          <Settings />
        </IconButton>
      </div>

      <div
        style={{
          cursor: 'pointer',
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '12px',
        }}
        onClick={() => {
          if (
            node.children.length > 0 ||
            (node.group.devices && node.group.devices.length > 0)
          ) {
            setCurrentGroups();
          }
        }}
      >
        <Icon color="primary" style={{ fontSize: '80px' }}>
          <FolderOpen style={{ fontSize: '80px' }} />
        </Icon>

        <Typography variant="h3">{node.group.name}</Typography>

        {(node.children.length > 0 ||
          (node.group.devices && node.group.devices?.length > 0)) && (
          <Typography variant="caption">
            {node.children.length +
              (node.group.devices ? node.group.devices.length : 0)}{' '}
            item(s)
          </Typography>
        )}
      </div>
    </Paper>
  );
}
