import { Tooltip } from '@mui/material';
import { CustomColor } from '../orgs/orgTypes';

export type ColorChangeHandler = (color: string) => void;

type Color =
  | { name: string; display: string; value: number[]; _id: string }
  | CustomColor;

interface ColorCircleProps {
  currentColor: string;
  onChange: ColorChangeHandler;
  colors:
    | { name: string; display: string; value: number[]; _id: string }[]
    | CustomColor[];
}
export default function ColorCircle({
  currentColor,
  colors,
  onChange,
}: ColorCircleProps) {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
      {colors.map((color: Color) => {
        const isSelected = currentColor === color.display;
        return (
          <Tooltip title={color.name}>
            <div
              key={color.name}
              onClick={(e) => onChange(color.display)}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                backgroundColor: isSelected ? 'transparent' : color.display,
                border: isSelected
                  ? `3px solid ${color.display}`
                  : '3px solid transparent',
                cursor: 'pointer',
              }}
            ></div>
          </Tooltip>
        );
      })}
    </div>
  );
}
