import { useContext, useState } from 'react';
import { DeviceModel } from '../../devices/Devices';
import { Icon, TableCell, Typography, IconButton } from '@mui/material';
import { UserContext } from '../../context/UserStateManager';
import { Redirect } from 'react-router-dom';
import { Settings } from '@mui/icons-material';
import HighlightIcon from '@mui/icons-material/Highlight';
import OrgGroupDeviceEditModal from '../OrgGroupDeviceEditModal';

interface DeviceTableRowProps {
  device: DeviceModel;
}

export function DeviceTableRow({ device }: DeviceTableRowProps) {
  const [redirect, setRedirect] = useState<string | null>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const userCon = useContext(UserContext);

  return (
    <>
      <TableCell width={1} style={{ padding: '2px' }}>
        <Icon
          color="primary"
          style={{
            paddingLeft: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <HighlightIcon />
        </Icon>
      </TableCell>
      <TableCell
        style={{ cursor: 'pointer' }}
        colSpan={6}
        onClick={() => {
          userCon.setState({
            ...userCon.state,
            currentDevice: device,
          });

          setRedirect(device?._id || null);
        }}
      >
        {device.name}
      </TableCell>
      <TableCell width={1}>
        {!device.group && <Typography color="error">Unassigned</Typography>}
      </TableCell>

      <TableCell width={1} style={{ padding: '2px' }}>
        <IconButton
          onClick={() => {
            setIsAddModalOpen(true);
          }}
        >
          <Settings />
        </IconButton>
      </TableCell>

      {isAddModalOpen && (
        <OrgGroupDeviceEditModal
          open={isAddModalOpen}
          onClose={() => {
            setIsAddModalOpen(false);
          }}
          device={device}
        />
      )}

      {redirect && <Redirect to={'/controller/' + redirect} />}
    </>
  );
}
