import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { UserContext } from '../context/UserStateManager';
import useTitle from '../../hooks/useTitle.hook';
import { Controller, useForm } from 'react-hook-form';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';

function UserProfile() {
  useTitle('Profile');
  const userCon = useContext(UserContext);
  const [changed, setChanged] = useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: userCon.state.user.firstName,
      lastName: userCon.state.user.lastName,
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Typography variant="h2">User Information</Typography>
          <form
            onSubmit={handleSubmit((userDto) =>
              fetchLogoutOn401(
                userCon,
                process.env.REACT_APP_BACKEND_URL + '/users/me/info',
                {
                  method: 'PATCH',
                  headers: {
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + userCon.state.jwtToken,
                  },
                  body: JSON.stringify(userDto),
                },
              )
                .then((resp) => {
                  setChanged(false);
                  if (resp.ok) return resp.json();
                })
                .then((user) => {
                  if (!user) return;
                  userCon.setState({
                    ...userCon.state,
                    user: {
                      ...userCon.state.user,
                      firstName: user.firstName,
                      lastName: user.lastName,
                    },
                  });
                }),
            )}
          >
            <div style={{ display: 'flex' }}>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    label=""
                    labelPlacement="start"
                    control={
                      <TextField
                        label="First Name"
                        value={value}
                        onChange={(e) => {
                          setChanged(true);
                          onChange(e);
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    }
                  />
                )}
                rules={{
                  required: 'Required',
                  maxLength: {
                    value: 50,
                    message: 'Must be less than 50 characters',
                  },
                }}
              />
              <div style={{ width: 16 }} />
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    label=""
                    labelPlacement="start"
                    control={
                      <TextField
                        label="Last Name"
                        value={value}
                        onChange={(e) => {
                          setChanged(true);
                          onChange(e);
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    }
                  />
                )}
                rules={{
                  required: 'Required',
                  maxLength: {
                    value: 50,
                    message: 'Must be less than 50 characters',
                  },
                }}
              />
            </div>
            {changed && (
              <Button
                color="primary"
                style={{ margin: '16px 0' }}
                type="submit"
                variant="contained"
              >
                Update Name
              </Button>
            )}
          </form>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Typography variant="h2">Organizations</Typography>
          <div
            style={{
              maxHeight: '50vh',
              overflowY: 'auto',
            }}
          >
            <Table stickyHeader>
              <TableHead
                sx={{
                  background: '#ffffff',
                  borderBottom: 'solid 2px #222222',
                }}
              >
                <TableRow>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userCon.state.user.orgs.map((org, i) => (
                  <TableRow key={i}>
                    <TableCell>{org.org.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UserProfile;
