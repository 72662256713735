import {
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import colorwashLogo from '../../assets/images/color-wash-logo-vertical.png';
import useTitle from '../../hooks/useTitle.hook';
import StatusCodeResponse from '../common/StatusCodeResponse';
import { refreshUserContext, UserContext } from '../context/UserStateManager';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type tNewPass = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  retype: string;
};

export const SetPassword = (newuser: boolean) => {
  const userCon = useContext(UserContext);
  const history = useHistory();
  const { email, token } = useParams<{ email: string; token: string }>();
  const theme = useTheme();
  const { handleSubmit, control, setError } = useForm<tNewPass>();
  const [statusCode, setStatusCode] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const timeLimit = 5000;

  useTitle(newuser ? 'Signup' : 'Set Password');

  const changeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const showToastMessage = () => {
    toast.success(
      'Account Created. In a few seconds you will be redirected to login with your new credentials.',
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: timeLimit,
      },
    );
  };

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const submit = async (userLogin: tNewPass) => {
    if (userLogin.password !== userLogin.retype) {
      setError('retype', {
        type: 'validate',
        message: 'Passwords should match.',
      });
      return;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const regexResult = passwordRegex.exec(userLogin.password);

    if (regexResult === null) {
      setError('password', {
        type: 'pattern',
        message:
          'Password should contain at least one of each: lowercase, uppercase, number. Minimum 8 characters.',
      });
      return;
    }

    const url = newuser ? '/invite/' : '/password/';
    const userDTO = newuser
      ? JSON.stringify({
          firstName: userLogin.firstName,
          lastName: userLogin.lastName,
          password: userLogin.password,
        })
      : JSON.stringify({ password: userLogin.password });

    let resp = await fetch(process.env.REACT_APP_BACKEND_URL + url + token, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: userDTO,
    });

    // login and get JWT
    resp = await fetch(process.env.REACT_APP_BACKEND_URL + '/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: decodeURIComponent(email),
        password: userLogin.password,
      }),
    });

    if (resp.status !== 201) {
      setStatusCode(resp.status);
      return;
    }

    const { access_token } = await resp.json();

    // only display for a new user
    if (newuser) {
      setDisableSubmit(true);
      showToastMessage();
      await delay(timeLimit);
    }

    // get user info
    refreshUserContext(
      {
        ...userCon.state,
        jwtToken: access_token,
      },
      userCon.setState,
    );
    history.push('/login');
  };

  useEffect(() => {
    (async () => {
      const res = await fetch(
        process.env.REACT_APP_BACKEND_URL + `/password/${email}/${token}`,
        { method: 'GET' },
      );
      const { isTokenValid } = await res.json();

      if (!isTokenValid) {
        setStatusCode(404);
      }

      setLoading(false);
    })();
  }, [email, token]);

  if (loading) return <div></div>;

  return (
    <div
      style={{
        margin: 'calc(33vh - 190px) auto',
        width: '312px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img style={{ width: '312px' }} src={colorwashLogo} alt="Colorwash" />
      <Paper
        sx={{
          marginTop: theme.spacing(4),
          '& form': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '& button': {
              margin: '0 auto',
              width: 'fit-content',
            },
            '& h1': {
              fontSize: '18px',
              fontWeight: 'bold',
            },
          },
        }}
      >
        {statusCode === 0 && (
          <form onSubmit={handleSubmit((userLogin) => submit(userLogin))}>
            <Typography variant="h1">
              {newuser ? 'Finish Account Setup' : 'Set Password'}
            </Typography>
            <TextField
              style={{ marginTop: '18px' }}
              sx={{ marginBottom: theme.spacing(2) }}
              label="Email"
              value={decodeURIComponent(email)}
              autoComplete="username"
              disabled
            />
            {newuser && (
              <>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ marginBottom: theme.spacing(2) }}
                      label="First Name"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{
                    required: 'Required',
                    maxLength: {
                      value: 50,
                      message: 'Must be less than 50 characters',
                    },
                  }}
                />

                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      sx={{ marginBottom: theme.spacing(2) }}
                      label="Last Name"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{
                    required: 'Required',
                    maxLength: {
                      value: 50,
                      message: 'Must be less than 50 characters',
                    },
                  }}
                />
              </>
            )}

            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  sx={{ marginBottom: theme.spacing(2) }}
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={changeShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              rules={{
                required: 'Required',
                maxLength: {
                  value: 128,
                  message: 'Must be less than 128 characters',
                },
              }}
            />

            <Controller
              name="retype"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  sx={{ marginBottom: theme.spacing(2) }}
                  label="Retype Password"
                  type={showPassword ? 'text' : 'password'}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={changeShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              rules={{
                required: 'Required',
                maxLength: {
                  value: 128,
                  message: 'Must be less than 128 characters',
                },
              }}
            />

            <Button
              color="primary"
              disabled={disableSubmit}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </form>
        )}

        <StatusCodeResponse
          statusCode={statusCode}
          codeResponses={[
            {
              statusCode: 405,
              response:
                'Information was set successfully. Please contact Organization Administrator.',
            },
            {
              statusCode: 404,
              response: 'Invalid Token',
            },
          ]}
        />
      </Paper>
      <ToastContainer />
    </div>
  );
};

export default SetPassword;
