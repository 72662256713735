import {
  Paper,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import moment from 'moment-timezone';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { UserContext } from '../context/UserStateManager';
import { ControllerTypeMap, OrgRole } from '../Enums';
import { DeviceModel, DeviceStatus } from './Devices';
import SearchIcon from '@mui/icons-material/Search';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { GroupTreeNode } from '../orgs/orgTypes';
import { getGroupNameById } from '../orgs/groupsHelpers';

export function deviceIsConnected(lastCheckin: Date) {
  return moment(lastCheckin).isAfter(moment().subtract(5, 'minutes'));
}

export function DeviceList({
  devices,
  groups,
}: {
  devices: DeviceModel[];
  groups: GroupTreeNode[];
}) {
  const history = useHistory();
  const userCon = useContext(UserContext);
  const [deviceStatuses, setDeviceStatuses] = useState<{
    [key: string]: DeviceStatus | null;
  }>({});
  const [groupNames, setGroupNames] = useState<{
    [key: string]: string;
  }>({});

  //All for searching and sorting the device list
  const [searchValue, setSearchValue] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [reverse, setReverse] = useState(false);
  const sortOptions = ['Name', 'Date Added'];

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // You can adjust the number of items per page

  // Calculate the number of pages needed

  const filteredDevices = devices
    .filter(
      (device, i) =>
        device.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        device.location?.toLowerCase().includes(searchValue.toLowerCase()) ||
        device.deviceID.toLowerCase().includes(searchValue.toLowerCase()) ||
        groupNames[device._id]
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
    )
    .sort((a, b) => {
      if (sortOption === 'Name') {
        if (!reverse) return a.name.localeCompare(b.name);
        else return b.name.localeCompare(a.name);
      } else if (sortOption === 'Date Added') {
        if (!reverse) return moment(a.createdAt).diff(moment(b.createdAt));
        else return moment(b.createdAt).diff(moment(a.createdAt));
      }
      return 0;
    });

  const totalPages = Math.ceil(filteredDevices.length / itemsPerPage);

  const paginatedDevices = filteredDevices.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  useEffect(() => {
    const groupMap: { [key: string]: string } = devices.reduce(
      (acc, device) => {
        if (
          device.group &&
          userCon.state.currentOrgGroupPaths &&
          userCon.state.currentOrgGroupTree
        ) {
          acc[device._id] =
            userCon.state.currentOrgGroupPaths.find((path) =>
              path.endsWith(
                getGroupNameById(
                  device.group || '',
                  userCon.state.currentOrgGroupTree ?? [],
                ) || '',
              ),
            ) || '';
          return acc;
        }
        acc[device._id] = '';
        return acc;
      },
      {} as { [key: string]: string },
    );
    setGroupNames(groupMap);
  }, [
    devices,
    userCon.state.currentOrgGroupPaths,
    userCon.state.currentOrgGroupTree,
  ]);

  useEffect(() => {
    const controller = new AbortController();
    (async function () {
      Promise.all(
        devices.map((device) =>
          fetchLogoutOn401(
            userCon,
            process.env.REACT_APP_BACKEND_URL + '/devices/status/' + device._id,
            {
              headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
              signal: controller.signal,
            },
          ),
        ),
      )
        .then((results) => {
          return Promise.all(
            results
              .filter((res) => !!res)
              .map((res) => (res && res.ok ? res.json() : null)),
          );
        })
        .then((data) => {
          const statusMap: { [key: string]: DeviceStatus | null } =
            devices.reduce((acc, device, index) => {
              acc[device._id] = data[index] || null;
              return acc;
            }, {} as { [key: string]: DeviceStatus | null });
          setDeviceStatuses(statusMap);
        })
        .catch((e) => {});
    })();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, userCon.state.jwtToken]);

  return (
    <div>
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h2" style={{ marginRight: '10px' }}>
          Controllers
        </Typography>

        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            marginTop: '5px',
            flexWrap: 'wrap-reverse',
          }}
        >
          <FormControl style={{ marginRight: '20px' }}>
            <InputLabel id="sort-label">Sort By</InputLabel>
            <Select
              labelId="sort-label"
              id="sort-select"
              style={{ minWidth: '100px', maxWidth: '150px' }}
              value={sortOption}
              label="Sort By"
              onChange={(e) => {
                setSortOption(String(e.target.value));
                setReverse(false);
              }}
            >
              {sortOptions.map((option, key) => {
                return (
                  <MenuItem
                    onClick={() => {
                      if (sortOption === option && reverse) {
                        setReverse(false);
                        setSortOption('');
                      } else if (sortOption === option && !reverse) {
                        setReverse(true);
                      }
                    }}
                    value={option}
                    key={key}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {option}
                      {option === sortOption &&
                        (reverse ? (
                          <ExpandMore style={{ fontSize: '20' }} />
                        ) : (
                          <ExpandLess style={{ fontSize: '20' }} />
                        ))}
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            inputRef={(input) => input && input.focus()}
            label="Search..."
            value={searchValue}
            style={{ minWidth: '100px', maxWidth: '150px' }}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={(e) => e.stopPropagation()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Can search based on name, location, and device ID">
                    <SearchIcon style={{ color: '#999999' }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <Grid container spacing={3}>
        {paginatedDevices.map((device, i) => (
          <Grid size={{ xs: 12, md: 12 }} key={i}>
            <Paper
              sx={{
                margin: '8px 0',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
              key={device._id}
              onClick={() => {
                userCon.setState({
                  ...userCon.state,
                  currentDevice: device,
                });
                history.push('/controller/' + device._id);
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ justifyContent: 'space-between' }}
              >
                <Grid size={{ xs: 12, md: 5 }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ marginRight: '4px' }} variant="h3">
                      Name:
                    </Typography>
                    <Typography sx={{ fontWeight: 300 }} variant="h3">
                      {device.name}
                    </Typography>
                  </div>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ marginRight: '4px' }} variant="h3">
                      Location:
                    </Typography>
                    <Typography sx={{ fontWeight: 300 }} variant="h3">
                      {device.location}
                    </Typography>
                  </div>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ marginRight: '4px' }} variant="h3">
                      Connected:
                    </Typography>
                    <div
                      style={{
                        height: '20px',
                        width: '20px',
                        borderRadius: '20px',
                        backgroundColor: deviceStatuses[device._id]
                          ? '#11E300'
                          : '#bebebe',
                      }}
                    />
                  </div>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ marginRight: '4px' }} variant="h3">
                      Last Checkin Time:
                    </Typography>
                    {device && device.lastCheckin && device.timeZone && (
                      <Typography sx={{ fontWeight: 300 }} variant="h3">
                        {moment(device.lastCheckin)
                          .tz(device.timeZone)
                          .format('hh:mm:ssa z')}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ marginRight: '4px' }} variant="h3">
                      Controller ID:
                    </Typography>
                    <Typography sx={{ fontWeight: 300 }} variant="h3">
                      {device.deviceID}
                    </Typography>
                  </div>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ marginRight: '4px' }} variant="h3">
                      Device Type:
                    </Typography>
                    <Typography sx={{ fontWeight: 300 }} variant="h3">
                      {device.configuration.controllerType
                        ? ControllerTypeMap[device.configuration.controllerType]
                        : ControllerTypeMap['DEFAULT_TYPE']}
                    </Typography>
                  </div>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ marginRight: '4px' }} variant="h3">
                      Device Group:
                    </Typography>
                    <Typography sx={{ fontWeight: 300 }} variant="h3">
                      {groupNames[device._id]
                        ? groupNames[device._id]
                        : 'Unassigned'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Pagination Component */}
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </div>
    </div>
  );
}

export default DeviceList;
