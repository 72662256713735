import {
  AppBar,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ShowLineupList from '../configuration/ShowLineupList';
import MusicShowLineupList from '../configuration/MusicShowLineupList';
import { ShowLineup } from '../admin/SystemLineupList';
import { LineupType } from '../Enums';

type AcceptResponse = {
  success: boolean;
  message?: string;
};

type props = {
  lineupType: string;
  title: string;
  closeDialog: () => void;
  showLineup: null | ShowLineup;
  submit: (showLineup: ShowLineup) => Promise<AcceptResponse>;
};

export default function EditLineupForm({
  lineupType,
  title,
  closeDialog,
  showLineup,
  submit,
}: props) {
  const { control, handleSubmit } = useForm<ShowLineup>({
    defaultValues: showLineup ?? {
      name: '',
      lineup: [],
    },
  });
  const [hasError, setHasError] = useState(false);
  const [acceptResponseMessage, setAcceptResponseMessage] = useState('');
  const theme = useTheme();

  return (
    <div style={{ minHeight: '100%', backgroundColor: '#F4F7FC' }}>
      <AppBar
        color="secondary"
        sx={{ height: `68px`, borderBottom: '1px solid #e0e0e0' }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => {
              closeDialog();
            }}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {showLineup
              ? 'Edit ' + title + ' Lineup'
              : 'Add ' + title + ' Lineup'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: '68px' }} />
      <div
        style={{
          margin: 'auto',
          marginTop: theme.spacing(2),
          maxWidth: `min(500px, calc(100% - ${
            parseInt(theme.spacing(2)) * 2
          }px))`,
        }}
      >
        <form
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit((lineup) => {
              submit(lineup).then(({ success, message }) => {
                if (success) {
                  closeDialog();
                } else {
                  setHasError(true);
                }
                setAcceptResponseMessage(message || '');
              });
            })(e);
          }}
        >
          <Paper sx={{ marginBottom: theme.spacing(2) }}>
            <Typography variant="h2">Lineup Information</Typography>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Name:"
                  labelPlacement="start"
                  control={
                    <TextField
                      style={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        width: '100%',
                      }}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'Required',
                maxLength: {
                  value: 50,
                  message: 'Must be less than 50 characters',
                },
              }}
            />
          </Paper>
          <Controller
            name="lineup"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                {lineupType === LineupType.MOOD_LIGHTING ? (
                  <ShowLineupList
                    showList={value}
                    setShows={onChange}
                    saveDisabled
                  />
                ) : (
                  <MusicShowLineupList
                    showList={value}
                    setShows={onChange}
                    saveDisabled
                  />
                )}
                {!!error && (
                  <Typography
                    style={{
                      fontSize: '16px',
                      textAlign: 'center',
                      marginTop: '16px',
                    }}
                    variant="caption"
                    color="error"
                  >
                    Lineup must contain at least one show.
                  </Typography>
                )}
                {hasError && (
                  <Typography
                    style={{
                      fontSize: '16px',
                      textAlign: 'center',
                      marginTop: '16px',
                    }}
                    variant="caption"
                    color="error"
                  >
                    {acceptResponseMessage}
                  </Typography>
                )}
              </>
            )}
            rules={{
              validate: {
                isAfter: (shows) => shows.length > 0,
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              marginTop: theme.spacing(3),
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            Save Lineup
          </Button>
        </form>
      </div>
    </div>
  );
}
