import useTitle from '../../hooks/useTitle.hook';
import TabNavigation from '../navigation/TabNavigation';
import Users from '../users/Users';
import OrgMusicLineupList from './OrgMusicLineupList';
import OrgSecurity from './OrgSecurity';
import OrgGroups from './OrgGroups';
import { OrgColorPicker } from './OrgColorPicker';
import GroupLineupsAndEvents from './GroupLineupsAndEvents';

function OrgSettings() {
  useTitle('Organization Settings');

  return (
    <div>
      <TabNavigation
        title="Organization Settings"
        content={[
          { title: 'USERS', component: <Users /> },
          { title: 'Groups', component: <OrgGroups /> },
          { title: 'Lineups & Events', component: <GroupLineupsAndEvents /> },

          { title: 'Colors', component: <OrgColorPicker /> },
          {
            title: 'Music Show Lineups',
            component: <OrgMusicLineupList />,
          },
          { title: 'SECURITY', component: <OrgSecurity /> },
        ]}
      />
    </div>
  );
}
export default OrgSettings;
