import {
  AppBar,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import StatusCodeResponse from '../common/StatusCodeResponse';
import { OrgModel } from './orgTypes';

type props = {
  orgData: null | OrgModel;
  closeDialog: Function;
  submitOrg: Function;
};

export function OrgForm({ orgData, closeDialog, submitOrg }: props) {
  const theme = useTheme();
  const { handleSubmit, control } = useForm<OrgModel>();
  const [statusCode, setStatusCode] = useState(0);

  async function submit(org: OrgModel) {
    if (orgData) {
      org._id = orgData._id;
    }
    const status = await submitOrg(org);
    if (status === 201 || status === 200) {
      closeDialog();
      return;
    }
    setStatusCode(status);
  }

  return (
    <div style={{ minHeight: '100%', backgroundColor: '#F4F7FC' }}>
      <AppBar
        color="secondary"
        sx={{ height: `68px`, borderBottom: '1px solid #e0e0e0' }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {orgData === null ? 'New Organization' : 'Edit Organization'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: '68px' }} />
      <div
        style={{
          margin: 'auto',
          marginTop: theme.spacing(2),
          maxWidth: `min(500px, calc(100% - ${
            parseInt(theme.spacing(2)) * 2
          }px))`,
        }}
      >
        <Paper>
          <form
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            onSubmit={handleSubmit((org) => submit(org))}
          >
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              {orgData === null ? 'New Organization' : 'Edit Organization'}
            </Typography>

            <Controller
              name="name"
              control={control}
              defaultValue={orgData ? orgData.name : ''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Name"
                  labelPlacement="start"
                  control={
                    <TextField
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'Required',
                maxLength: {
                  value: 100,
                  message: 'Must be less than 100 characters',
                },
              }}
            />
            <Button
              color="primary"
              style={{ marginTop: '16px' }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </form>
          <StatusCodeResponse
            statusCode={statusCode}
            codeResponses={[
              {
                statusCode: 409,
                response: 'Duplicate Organization Found',
              },
            ]}
          />
        </Paper>
      </div>
    </div>
  );
}

export default OrgForm;
