import { Button, Divider, Paper, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UniverseVariant } from './UniverseVariant';
import { FixtureVariant } from './FixtureVariant';
import { PixelVariant } from './PixelVariant';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { UserContext, refreshUserContext } from '../context/UserStateManager';
import { fetchStatusJson } from '../configuration/DeviceStatusPage';
import { DeviceStatus } from '../devices/Devices';

export function Troubleshooting() {
  const [variant, setVariant] = useState('universe');
  const [on, setOn] = useState(false);
  const userCon = useContext(UserContext);
  const [error, setError] = useState('');

  const stopTroubleshoot = async () => {
    setOn(false);

    try {
      await fetchLogoutOn401(
        userCon,
        `${process.env.REACT_APP_BACKEND_URL}/devices/troubleshoot/stop/${userCon.state.currentDevice?._id}`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + userCon.state.jwtToken,
          },
        },
      );
      setOn(false);
    } catch (err) {
      setError('Error stopping troubleshoot');
    }
  };

  const makeRequest = async (pixels: number[][], duration: number) => {
    const formattedData = {
      duration,
      rgbwPixels: pixels,
    };
    try {
      let resp = await fetchLogoutOn401(
        userCon,
        `${process.env.REACT_APP_BACKEND_URL}/devices/troubleshoot/start/${userCon.state.currentDevice?._id}`,

        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + userCon.state.jwtToken,
          },
          body: JSON.stringify(formattedData),
        },
      );
      if (resp.ok) {
        refreshUserContext(userCon.state, userCon.setState);
        setOn(true);
        setTimeout(() => handleFetchStatus(), duration * 1000);
      }
    } catch (err) {
      setError('Error starting troubleshoot');
    }
  };

  const handleFetchStatus = useCallback(async () => {
    try {
      const status = (await fetchStatusJson(userCon)) as DeviceStatus;
      if (status.debugEnabled) {
        setOn(true);
      } else {
        setOn(false);
      }
    } catch (err) {
      setError('Could not connect to device');
    }
  }, [userCon]);

  useEffect(() => {
    handleFetchStatus();
  }, []);

  const buttons = [
    { label: 'Universe', value: 'universe' },
    { label: 'Fixture', value: 'fixture' },
    { label: 'Pixel', value: 'pixel' },
  ];

  return (
    <div>
      <Paper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography variant="h3">Troubleshoot</Typography>
        </div>

        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {buttons.map((button, index) => (
            <Button
              key={index}
              onClick={() => setVariant(button.value)}
              variant={variant === button.value ? 'contained' : 'text'}
              style={{ width: '30%' }}
              disableElevation
            >
              {button.label}
            </Button>
          ))}
        </div>
        <Divider
          style={{
            marginTop: '8px',
            marginBottom: '16px',
            backgroundColor: '#707070',
          }}
        />
        {variant === 'pixel' ? (
          <PixelVariant
            makeRequest={makeRequest}
            on={on}
            stopTrouble={stopTroubleshoot}
            error={error}
          />
        ) : variant === 'fixture' ? (
          <FixtureVariant
            makeRequest={makeRequest}
            on={on}
            stopTrouble={stopTroubleshoot}
            error={error}
          />
        ) : (
          <UniverseVariant
            makeRequest={makeRequest}
            on={on}
            stopTrouble={stopTroubleshoot}
            error={error}
          />
        )}
        {error && (
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <span style={{ color: 'red' }}>{error}</span>
          </div>
        )}
      </Paper>
    </div>
  );
}
