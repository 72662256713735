import {
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { fetchOrgInfo, UserContext } from '../context/UserStateManager';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from 'react-query';
import {
  buildAllPaths,
  buildGroupTree,
  getGroupDevices,
  getGroups,
} from '../orgs/groupsHelpers';
import { Group } from '../orgs/orgTypes';

function OrganizationSelection() {
  const userCon = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState('');
  const [fetching, setFetching] = useState(false);

  const groupQuery = useQuery<Group[]>(
    ['get-groups', userCon.state.currentOrg?.org._id],
    async () => await getGroups(userCon),
    {
      onSuccess: async (data) => {
        for (var x = 0; x < data.length; x++) {
          data[x].devices = await getGroupDevices(userCon, data[x]._id);
        }
        const groupTree = buildGroupTree(data);
        userCon.setState({
          ...userCon.state,
          currentOrgGroupPaths: buildAllPaths(groupTree),
          currentOrgGroupTree: groupTree,
        });
      },
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: userCon.state.user.orgs.length == 0 ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography>
          {userCon.state.currentOrg?.org?.name ?? 'Select an Organization'}
        </Typography>
        <ExpandMoreOutlinedIcon />
      </div>

      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setSearch('');
        }}
      >
        <Paper elevation={0}>
          {userCon.state.user.orgs.length > 9 && (
            <TextField
              inputRef={(input) => input && input.focus()}
              label="Search"
              value={search}
              style={{ margin: '8px 16px', width: '91%' }}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: '#999999' }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {userCon.state.user.orgs
              .filter((obj) =>
                search === ''
                  ? true
                  : obj.org.name.toLowerCase().includes(search.toLowerCase()),
              )
              .map((ele) => (
                <MenuItem
                  key={ele.org._id}
                  disabled={
                    fetching ||
                    userCon.state.currentOrg?.org._id === ele.org._id
                  }
                  onClick={() => {
                    setAnchorEl(null);
                    if (userCon.state.currentOrg?.org._id !== ele.org._id) {
                      setFetching(true);
                      fetchOrgInfo(userCon, ele.org._id)
                        .then((org) =>
                          userCon.setState({
                            ...userCon.state,
                            currentOrg: { ...ele, org },
                            currentDevice: null,
                          }),
                        )
                        .then(() => {
                          groupQuery.refetch();
                        })
                        .finally(() => setFetching(false));
                    }
                  }}
                >
                  <Typography>
                    {ele.org.name.length > 40
                      ? ele.org.name.slice(0, 40) + '...'
                      : ele.org.name}
                  </Typography>
                </MenuItem>
              ))}
          </div>
        </Paper>
      </Menu>
    </div>
  );
}

export default OrganizationSelection;
