import {
  FormControlLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Button,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DurationComponent } from './DurationComponent';

type FixtureFormValues = {
  universe: string;
  fixture: number;
  color: string;
  duration: number;
};

export function FixtureVariant({
  makeRequest,
  on,
  stopTrouble,
  error,
}: {
  makeRequest: (pixels: number[][], duration: number) => Promise<void>;
  on: boolean;
  stopTrouble: () => void;
  error: string;
}) {
  const colors = ['Red', 'Green', 'Blue', 'White', 'Off'];
  const universes = ['A', 'B', 'C'];
  const { control, handleSubmit } = useForm<FixtureFormValues>();

  const onSubmit = (data: FixtureFormValues) => {
    const pixelsToSendBack: number[][] = [];
    const universeIndex = universes.indexOf(data.universe);
    const colorIndex = colors.indexOf(data.color.toString());

    pixelsToSendBack[colorIndex] = [
      (data.fixture - 1) * 4 + 1 + 128 * universeIndex,
      (data.fixture - 1) * 4 + 2 + 128 * universeIndex,
      (data.fixture - 1) * 4 + 3 + 128 * universeIndex,
      (data.fixture - 1) * 4 + 4 + 128 * universeIndex,
    ];

    colors.forEach((_, index) => {
      if (!pixelsToSendBack[index]) {
        pixelsToSendBack[index] = [0];
      }
    });

    makeRequest(pixelsToSendBack, data.duration);
  };

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: '30px' }}>
        Choose a color for a fixture within the selected universe. Fixtures are
        numbered from 1 to 32. Include a duration.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="universe"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <FormControlLabel
                label="Universe:"
                labelPlacement="start"
                style={{ maxWidth: '400px' }}
                control={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Select
                      value={value}
                      style={{ width: '175px' }}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                      error={!!error}
                    >
                      {universes.map((value, index) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText error={true}>
                      {error?.message}
                    </FormHelperText>
                  </div>
                }
              />
            );
          }}
          rules={{ required: 'A universe is required' }}
        />

        <Controller
          name="fixture"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControlLabel
              label="Fixture:"
              labelPlacement="start"
              style={{ maxWidth: '400px' }}
              onChange={onChange}
              control={
                <TextField
                  type="number"
                  value={value}
                  style={{ width: '175px' }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              }
            />
          )}
          rules={{
            required: 'A fixture is required',
            validate: (value) => {
              if (parseInt(value) < 1) return 'Must be at least 1';
              if (parseInt(value) > 32) return 'Must be less than 33';
              if (parseFloat(value) % 1 !== 0) return 'Must be a whole number';
            },
          }}
        />

        <Controller
          name="color"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <FormControlLabel
                label="Color:"
                labelPlacement="start"
                style={{ maxWidth: '400px' }}
                control={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Select
                      value={value}
                      style={{ width: '175px' }}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                      error={!!error}
                    >
                      {colors.map((value, index) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText error={true}>
                      {error?.message}
                    </FormHelperText>
                  </div>
                }
              />
            );
          }}
          rules={{ required: 'A universe is required' }}
        />

        <DurationComponent control={control} />
        <div
          style={{ display: 'flex', justifyContent: 'end', marginTop: '16px' }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={on || error.length !== 0}
          >
            Submit
          </Button>
        </div>
      </form>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {on && (
          <Button onClick={stopTrouble} variant="outlined" color="primary">
            Abort Troubleshoot
          </Button>
        )}
      </div>
    </>
  );
}
