import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Input,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Controller, useForm } from 'react-hook-form';
import { OrgRole, OrgRoleMap, SysRole, SysRoleMap } from '../Enums';
import { UserModel } from './Users';
import { useContext, useEffect, useState } from 'react';
import StatusCodeResponse from '../common/StatusCodeResponse';
import { UserContext } from '../context/UserStateManager';
import { DeviceModel } from '../devices/Devices';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { Autocomplete } from '@mui/lab';

type props = {
  closeDialog: Function;
  userData: null | UserModel;
  admin?: boolean;
  submitUser: Function;
  resetUserPassword: Function;
};

interface UserFormModel {
  email: string;
  orgs: string[];
  devices: string[];
  role: SysRole | OrgRole;
}

export function UserForm({
  admin,
  userData,
  closeDialog,
  submitUser,
  resetUserPassword,
}: props) {
  const theme = useTheme();
  const userCon = useContext(UserContext);
  const { handleSubmit, control, watch } = useForm<UserFormModel>();
  const [statusCode, setStatusCode] = useState(0);
  const [passwordReset, setPasswordReset] = useState(false);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [modal, setModal] = useState({ open: false, message: '' });
  const role = watch('role');

  useEffect(() => {
    // get list of devices
    fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL +
        '/orgs/' +
        userCon.state.currentOrg?.org._id +
        '/devices',
      {
        headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
      },
    )
      .then((res) => {
        if (res.status !== 200) setStatusCode(999);
        else return res.json();
      })
      .then((obj: DeviceModel[]) => setDevices(obj));
  }, [userCon]);

  async function resetPassword(user: UserModel | null) {
    if (!user) return;

    const status = await resetUserPassword(user);

    if (status === 201) setPasswordReset(true);
  }

  async function submit(userFormData: UserFormModel) {
    let status;
    if (admin) {
      // admin settings

      let userDTO: any = {};
      if (userData) {
        // edit user
        // reformat user data for DTO
        let orgs: any = userData.orgs
          .map((obj) => ({
            org: obj.org._id,
            role: obj.role,
          }))
          .filter(({ org }) => userFormData.orgs.indexOf(org) !== -1); // filter user data for removal from orgs
        // merge existing org & roles with new orgs & ORG_USER role
        for (let id of userFormData.orgs) {
          let exists = false;
          orgs.forEach((obj: any) => {
            if (obj.org === id) exists = true;
          });
          if (!exists) {
            orgs.push({ org: id, role: OrgRole.ORG_USER });
          }
        }
        // insert the user's id for PATCH
        userDTO = {
          ...userFormData,
          _id: userData._id,
          orgs,
        };
        if (userCon.state.user.role === SysRole.SYSTEM_MANAGER)
          delete userDTO.role;
      } else {
        // new user
        // populate roles into org relations
        userDTO = {
          ...userFormData,
          firstName: '',
          lastName: '',
          orgs: userFormData.orgs.map((id) => ({
            org: id,
            role: OrgRole.ORG_USER,
          })),
        };
      }
      status = await submitUser(userDTO);
    } else {
      // org settings
      const userOrgDTO = {
        devices: userFormData.devices ?? [],
        orgRole: userFormData.role,
      };

      if (userData) {
        // edit org user
        status = await submitUser(userData._id, userOrgDTO);
      } else {
        // invite org user
        status = await submitUser(userFormData.email, userOrgDTO);
      }
    }

    if (status === 201 || status === 200) {
      if (!userData)
        setModal({
          open: true,
          message: `User successfully ${admin ? 'created' : 'added'}.`,
        });
      else closeDialog();
      return;
    } else if (status === 500) {
      if (!userData)
        setModal({
          open: true,
          message: `User successfully ${
            admin ? 'created' : 'added'
          }, email invitation send failed.`,
        });
      else closeDialog();
      return;
    }

    setStatusCode(status);
  }

  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    if (admin) return;

    if (userData?.orgs[0].devices.length === 0 || !userData) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices.length]);

  return (
    <div style={{ minHeight: '100%', backgroundColor: '#F4F7FC' }}>
      <AppBar
        color="secondary"
        sx={{ height: `68px`, borderBottom: '1px solid #e0e0e0' }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {userData === null ? 'Invite User' : 'Edit User'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: '68px' }} />
      <div
        style={{
          margin: 'auto',
          marginTop: theme.spacing(2),
          maxWidth: `min(500px, calc(100% - ${
            parseInt(theme.spacing(2)) * 2
          }px))`,
        }}
      >
        <Paper>
          <form
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            onSubmit={handleSubmit((user) => submit(user))}
          >
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              User Information
            </Typography>
            {userData && (
              <div>
                <Typography
                  sx={{ margin: theme.spacing(2) + 'px 0' }}
                  variant="h3"
                >
                  {`Name: ${userData.firstName + ' ' + userData.lastName}`}
                </Typography>
                <Typography
                  sx={{ margin: theme.spacing(2) + 'px 0' }}
                  variant="h3"
                >
                  {`Email: ${userData.email}`}
                </Typography>
              </div>
            )}

            {userData && !userData.hasAcceptedInvite && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                }}
              >
                <Typography variant="h6">
                  This user has not accepted your invite
                </Typography>
                <Button
                  color="primary"
                  variant="text"
                  disabled={passwordReset}
                  onClick={() => resetPassword(userData)}
                >
                  Resend Invite
                </Button>
              </div>
            )}

            {!userData && (
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    label="Email Address"
                    labelPlacement="start"
                    control={
                      <TextField
                        type="email"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    }
                  />
                )}
                rules={{
                  required: 'Required',
                  maxLength: {
                    value: 320,
                    message: 'Must be less than 320 characters',
                  },
                }}
              />
            )}

            {admin ? (
              <Controller
                name="orgs"
                control={control}
                defaultValue={
                  userData ? userData.orgs.map((ele) => ele.org._id) : []
                }
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    label="Organizations"
                    labelPlacement="start"
                    control={
                      <Autocomplete
                        disableCloseOnSelect
                        multiple
                        style={{ minWidth: '180px', maxWidth: '250px' }}
                        options={userCon.state.user.orgs}
                        getOptionLabel={(option) => option.org.name}
                        value={userCon.state.user.orgs.filter((org) =>
                          value.includes(org.org._id),
                        )}
                        onChange={(event, newValue) => {
                          onChange(newValue.map((org) => org.org._id));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Search..."
                          />
                        )}
                      />
                    }
                  />
                )}
                rules={{ required: false }}
              />
            ) : role === OrgRole.ORG_USER || role === OrgRole.ORG_VIEWER ? (
              <Controller
                name="devices"
                control={control}
                defaultValue={
                  userData
                    ? userData.orgs.find(
                        (obj) =>
                          obj.org._id === userCon.state?.currentOrg?.org?._id,
                      )?.devices
                    : []
                }
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const handleToggleSelectAll = () => {
                    setSelectAll((prev) => {
                      value.length > 0 && onChange([]);
                      return !prev;
                    });
                  };

                  const selectedDevices = devices.filter((device) =>
                    value.includes(device._id),
                  );

                  return (
                    <>
                      <FormControlLabel
                        labelPlacement="start"
                        label="All Devices"
                        style={{ marginTop: '20px' }}
                        control={
                          <Tooltip
                            title={
                              'This includes any newly added devices in the future.'
                            }
                          >
                            <Checkbox
                              checked={selectAll}
                              onChange={handleToggleSelectAll}
                            />
                          </Tooltip>
                        }
                      />
                      <FormControlLabel
                        label="Devices"
                        labelPlacement="start"
                        control={
                          <>
                            <Autocomplete
                              style={{
                                width: '210px',
                                overflow: 'clip',
                                textOverflow: 'ellipsis',
                              }}
                              multiple
                              disabled={selectAll}
                              disableCloseOnSelect
                              options={devices}
                              limitTags={3}
                              getOptionLabel={(option) => option.name}
                              value={selectedDevices}
                              onChange={(event, newValue) => {
                                onChange(newValue.map((device) => device._id));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Search..."
                                  error={!!error} // This will set error state on the TextField
                                />
                              )}
                            />
                          </>
                        }
                      />
                      {error && (
                        <p
                          style={{
                            color: 'red',
                            marginTop: '8px',
                            fontSize: '14px',
                          }}
                        >
                          Devices are required unless 'All Devices' is selected
                        </p>
                      )}
                    </>
                  );
                }}
                rules={
                  selectAll === true ? { required: false } : { required: true }
                }
              />
            ) : null}

            <Controller
              name="role"
              control={control}
              defaultValue={
                userData
                  ? admin
                    ? userData.role
                    : userData.orgs.filter(
                        (ele) =>
                          ele.org._id === userCon.state.currentOrg?.org._id,
                      )[0].role
                  : Object.entries(admin ? SysRole : OrgRole)[0][0]
              }
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="User Type"
                  labelPlacement="start"
                  control={
                    <Select
                      value={value}
                      onChange={onChange}
                      disabled={
                        admin &&
                        userCon.state.user.role === SysRole.SYSTEM_MANAGER
                      }
                    >
                      {Object.keys(admin ? SysRole : OrgRole).map((role, i) => (
                        <MenuItem key={role + i} value={role}>
                          {admin
                            ? SysRoleMap[role as SysRole]
                            : OrgRoleMap[role as OrgRole]}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              )}
              rules={{ required: 'Required' }}
            />

            <Button
              color="primary"
              style={{ marginTop: '16px' }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </form>
          <StatusCodeResponse
            statusCode={statusCode}
            codeResponses={[
              {
                statusCode: 409,
                response: 'Duplicate Email Found',
              },
              {
                statusCode: 999,
                response: 'Error Fetching Devices',
              },
            ]}
          />
        </Paper>
      </div>
      <Modal
        sx={{ margin: theme.spacing(2) }}
        open={modal.open}
        onClose={() => {
          setModal({ ...modal, open: false });
          closeDialog();
        }}
      >
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '20vh auto',
            width: 'fit-content',
          }}
        >
          <Typography variant="h3">{modal.message}</Typography>
          <Button
            style={{ margin: '16px auto 8px auto', width: 'fit-content' }}
            color="primary"
            variant="contained"
            onClick={() => {
              setModal({ ...modal, open: false });
              closeDialog();
            }}
          >
            Ok
          </Button>
        </Paper>
      </Modal>
    </div>
  );
}

export default UserForm;
