import GroupEventsList from './GroupEventsList';
import GroupLineupList from './GroupLineupList';
import { ToastContainer } from 'react-toastify';
import { useContext, useEffect } from 'react';
import { refreshUserContext, UserContext } from '../context/UserStateManager';

export default function GroupLineupsAndEvents() {
  const userCon = useContext(UserContext);

  //THIS IS GROSS - THINK OF FIX (hi future developer lol)
  useEffect(() => {
    refreshUserContext(userCon.state, userCon.setState);
  }, []);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <GroupEventsList
          events={userCon.state.currentOrg?.org.defaultEvents ?? []}
        />

        <GroupLineupList />
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
