import { Button } from '@mui/material';

interface QuickSettingButtonProps {
  title: string;
  active: boolean;
  onClick: Function;
  disabled?: boolean;
}
export default function QuickSettingButton({
  title,
  onClick,
  active,
  disabled,
}: QuickSettingButtonProps) {
  return (
    <Button
      variant="contained"
      color={'primary'}
      disabled={disabled}
      style={
        active && !disabled
          ? {
              backgroundColor: '#4c8c1a',
              color: '#ffffff',
              width: '200px',
              height: '50px',
            }
          : {
              width: '200px',
              height: '50px',
            }
      }
      onClick={() => {
        onClick();
      }}
    >
      {title} Mode
    </Button>
  );
}
