import {
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserStateManager';

import { ShowName, ShowNameMap, WHITE_VALUE } from '../Enums';
import CustomerControlShowDialog from './CustomerControlShowDialog';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { textColorFromBg } from '../orgs/helpers';
import { OrgColorContext } from '../context/OrgContext';

export interface CustomerControlShowConfiguration {
  showName: ShowName;
  name: string;
  description: string;
  imageUrl: string;
  colors?: number[][];
  speed?: number;
  backward?: boolean;
}

export const defaultCustomerControlShowConfiguration: CustomerControlShowConfiguration =
  {
    showName: ShowName.SOLID,
    colors: [[...WHITE_VALUE]],
    speed: 5,
    backward: false,
    name: 'Solid White',
    description: '',
    imageUrl: '',
  };

export default function CustomerControlShowLineup() {
  const userCon = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showList, setShowList] = useState<CustomerControlShowConfiguration[]>(
    [],
  );
  const [showsLoaded, setShowsLoaded] = useState(false);
  const [dialogState, setDialogState] = useState({
    open: false,
    showData: null as null | CustomerControlShowConfiguration,
    row: -1,
  });
  const { mapColorValues } = useContext(OrgColorContext);

  const closeDialog = () =>
    setDialogState({ open: false, showData: null, row: -1 });

  const setShows = async (showList: CustomerControlShowConfiguration[]) => {
    const res = await fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/customer-control/admin/showlist',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
        body: JSON.stringify(showList),
      },
    );
    if (res.ok) {
      setShowList(showList);
    } else {
    }
  };

  useEffect(() => {
    if (showsLoaded) return;
    fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL + '/customer-control/admin/showlist',
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + userCon.state.jwtToken,
        },
      },
    )
      .then((res) => res.json())
      .then((shows) => setShowList(shows))
      .then(() => setShowsLoaded(true))
      .catch((e) => console.log('Error getting showlist: ', e));
  }, [showsLoaded, userCon]);

  return (
    <div>
      <Paper
        sx={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}
      >
        <Typography variant="h3" style={{ marginBottom: '8px' }}>
          Customer Control Show Lineup
        </Typography>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Show Type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Colors</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showList.map((show, i) => (
                <TableRow key={i}>
                  <TableCell width="1" padding="none">
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setDialogState({
                          ...dialogState,
                          showData: show,
                          row: i,
                        });
                      }}
                      style={{ padding: 0 }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{ShowNameMap[show.showName]}</TableCell>
                  <TableCell>{show.name}</TableCell>
                  <TableCell padding="none">
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        minWidth: '80px',
                      }}
                    >
                      {show.colors?.map((hex, i) => {
                        const color = mapColorValues([hex])[0];
                        return (
                          <div
                            key={i}
                            style={{
                              width: '16px',
                              height: '16px',
                              margin: '4px 8px',
                              padding: '3px',
                              borderRadius: '6px',
                              border: '1px solid',
                              backgroundColor: color.display,
                              borderColor: '#000000',
                              color: textColorFromBg(color.display),
                            }}
                          ></div>
                        );
                      })}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {dialogState.row !== 0 && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                const newShowList = [...showList]; // Create a shallow copy of showList
                [
                  newShowList[dialogState.row - 1],
                  newShowList[dialogState.row],
                ] = [
                  newShowList[dialogState.row],
                  newShowList[dialogState.row - 1],
                ];
                setShows(newShowList); // Update state with the modified copy
              }}
            >
              <Typography>Move Up</Typography>
            </MenuItem>
          )}
          {dialogState.row !== showList.length - 1 && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                const newShowList = [...showList]; // Create a shallow copy of showList
                [
                  newShowList[dialogState.row + 1],
                  newShowList[dialogState.row],
                ] = [
                  newShowList[dialogState.row],
                  newShowList[dialogState.row + 1],
                ];
                setShows(newShowList); // Update state with the modified copy
              }}
            >
              <Typography>Move Down</Typography>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setDialogState({ ...dialogState, open: true });
            }}
          >
            <Typography>Edit</Typography>
          </MenuItem>
          <MenuItem
            color="error"
            onClick={() => {
              setAnchorEl(null);
              setShows(showList.filter((_, i) => i !== dialogState.row));
            }}
          >
            <Typography color="error">Delete</Typography>
          </MenuItem>
        </Menu>
        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '32px auto' }}
            onClick={() => {
              setDialogState({ open: true, showData: null, row: -1 });
            }}
          >
            Add a Show
          </Button>
        </div>
        <Dialog fullScreen open={dialogState.open} onClose={closeDialog}>
          <CustomerControlShowDialog
            closeDialog={closeDialog}
            showData={{
              ...defaultCustomerControlShowConfiguration,
              ...dialogState.showData,
            }}
            submitShow={(show) => {
              if (dialogState.showData) {
                setShows(
                  showList.map((oldShow, index) =>
                    dialogState.row === index ? show : oldShow,
                  ),
                );
              } else {
                setShows([...showList, show]);
              }
            }}
          />
        </Dialog>
      </Paper>
    </div>
  );
}
