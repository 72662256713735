import {
  FormControlLabel,
  Paper,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext } from 'react';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import {
  refreshUserContext,
  tUserContext,
  UserContext,
} from '../context/UserStateManager';

async function toggleAutoRegistration(enabled: boolean, userCon: tUserContext) {
  let res = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org?._id}/registration`,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        enableRegistration: enabled,
      }),
    },
  );
  if (res.ok) refreshUserContext(userCon.state, userCon.setState);
}

async function toggleQuickChangeBar(enabled: boolean, userCon: tUserContext) {
  let res = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org?._id}/quickchangebar`,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        showQuickChangeBar: enabled,
      }),
    },
  );
  if (res.ok) refreshUserContext(userCon.state, userCon.setState);
}

export default function OrgSecurity() {
  const userCon = useContext(UserContext);
  const theme = useTheme();

  return (
    <div>
      <Paper>
        <Typography variant="h3">Automatic Registration</Typography>
        <FormControlLabel
          label="Enable Automatic Registration"
          labelPlacement="start"
          sx={{
            justifyContent: 'start',
            margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
          }}
          control={
            <Switch
              checked={userCon.state.currentOrg?.org.enableRegistration}
              color="primary"
              onChange={(e) => {
                toggleAutoRegistration(e.target.checked, userCon);
              }}
            />
          }
        />

        <Typography variant="h3" style={{ marginTop: '20px' }}>
          Quick Access Bar
        </Typography>
        <FormControlLabel
          label="Enable Quick Access Bar"
          labelPlacement="start"
          sx={{
            justifyContent: 'start',
            margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
          }}
          control={
            <Switch
              checked={userCon.state.currentOrg?.org.showQuickChangeBar}
              color="primary"
              onChange={(e) => {
                toggleQuickChangeBar(e.target.checked, userCon);
              }}
            />
          }
        />
      </Paper>
    </div>
  );
}
