import { Typography } from '@mui/material';

export function PixelMapTable({ pixelMap }: { pixelMap?: number[][] }) {
  try {
    if (!pixelMap || !pixelMap.length) throw new Error('Invalid json');
    return (
      <table style={{ borderCollapse: 'collapse', textAlign: 'right' }}>
        <tr>
          {pixelMap[0].map((index) => (
            <td style={{ border: '1px solid black', padding: '2px 4px' }}>
              <b>{index}</b>
            </td>
          ))}
        </tr>
        {pixelMap.slice(1).map((pixelArray) => (
          <tr>
            {pixelArray.map((pixel) => (
              <td style={{ border: '1px solid black', padding: '2px 4px' }}>
                {pixel}
              </td>
            ))}
          </tr>
        ))}
      </table>
    );
  } catch (e) {
    return <Typography>Invalid pixel map json</Typography>;
  }
}
