import {
  List,
  Divider,
  ListItemText,
  ListItem,
  ListItemButton,
} from '@mui/material';
import ListItemLink from './ListItemLink';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import UserProfileMenu from './UserProfileMenu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useContext } from 'react';
import {
  setNoAuth,
  tUserContext,
  UserContext,
} from '../context/UserStateManager';
import OrganizationSelection from './OrganizationSelection';
import { OrgRole, SysRole } from '../Enums';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const navLinks = [
  {
    title: `Controllers`,
    path: `/controller`,
    icon: AssignmentOutlinedIcon,
    condition: () => true,
  },
  {
    title: 'Register Controller',
    path: '/register',
    icon: AddOutlinedIcon,
    condition: (userCon: tUserContext) =>
      userCon.state.currentOrg?.role !== OrgRole.ORG_VIEWER,
  },
  {
    title: `Organization Settings`,
    path: `/organization`,
    icon: SettingsOutlinedIcon,
    condition: (userCon: tUserContext) =>
      userCon.state.user.role !== SysRole.SYSTEM_USER ||
      userCon.state.currentOrg?.role === OrgRole.ORG_ADMIN,
  },
  {
    title: `Profile`,
    path: `/user`,
    icon: AccountCircleOutlinedIcon,
    condition: () => true,
  },
];

function DrawerContent({ closeDrawer }: { closeDrawer?: Function }) {
  const userCon = useContext(UserContext);

  return (
    <div
      style={{
        padding: '0 8px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <List
        component="nav"
        aria-labelledby="main navigation"
        sx={{ flexGrow: 1 }}
      >
        <ListItem>
          <UserProfileMenu />
        </ListItem>
        <ListItemButton>
          <OrganizationSelection />
        </ListItemButton>
        {navLinks.map(
          ({ title, path, icon, condition }) =>
            condition(userCon) && (
              <div
                key={title}
                onClick={() => {
                  if (closeDrawer) closeDrawer();
                  userCon.setState({
                    ...userCon.state,
                    currentDevice: null,
                  });
                }}
              >
                <ListItemLink primary={title} to={path} icon={icon} />
              </div>
            ),
        )}
        {userCon.state.user.role !== SysRole.SYSTEM_USER && (
          <div
            onClick={() => {
              if (closeDrawer) closeDrawer();
            }}
          >
            <Divider
              sx={{ root: { height: '2px', backgroundColor: '#707070' } }}
            />
            <ListItemLink
              key="admin"
              primary="Admin Settings"
              to="/admin"
              icon={SupervisorAccountOutlinedIcon}
            />
          </div>
        )}
      </List>

      <List>
        <ListItemButton
          onClick={() => setNoAuth(userCon)}
          sx={{ marginBottom: '16px' }}
        >
          <ExitToAppIcon color="error" />
          <div style={{ width: '16px' }} />
          <ListItemText
            primaryTypographyProps={{ color: 'error' }}
            primary="Logout"
          />
        </ListItemButton>
      </List>
    </div>
  );
}

export default DrawerContent;
