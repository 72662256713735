import {
  Button,
  CircularProgress,
  Modal,
  Paper,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useContext } from 'react';
import { DeviceModel } from '../devices/Devices';
import { Controller, useForm } from 'react-hook-form';
import {
  findParentGroupIdByPath,
  getGroupNameById,
  updateGroupDevices,
} from './groupsHelpers';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { refreshUserContext, UserContext } from '../context/UserStateManager';

interface ModalProps {
  open: boolean;
  onClose: Function;
  device: DeviceModel;
  setUserState?: Function;
}

export default function OrgGroupDeviceEditModal({
  open,
  onClose,
  device,
  setUserState,
}: ModalProps) {
  const queryClient = useQueryClient();
  const userCon = useContext(UserContext);
  const paths = userCon.state.currentOrgGroupPaths ?? [];
  const groupTree = userCon.state.currentOrgGroupTree ?? [];

  function getPath(groupName: string) {
    if (groupName === '') return 'None';
    for (const path of paths) {
      if (path.endsWith(groupName)) {
        return path;
      }
    }
    return '';
  }

  const { control, handleSubmit, setValue, getValues } = useForm({});

  const { mutate: mututateGroupDevices, isLoading } = useMutation(
    'updateOrCreate',
    async (variables: {
      groupId: string;
      newDevices: DeviceModel[];
      deleteDevices: DeviceModel[];
    }) =>
      await updateGroupDevices(
        userCon,
        variables.groupId,
        variables.newDevices,
        variables.deleteDevices,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-groups');
        await queryClient.invalidateQueries('getUngroupedDevices');
        setUserState && (await queryClient.invalidateQueries('get-devices'));
        setUserState &&
          refreshUserContext(
            {
              ...userCon.state,
              currentDevice: {
                ...(userCon.state.currentDevice as DeviceModel),
                group:
                  findParentGroupIdByPath(getValues().groupId, groupTree) || '',
              },
            },
            userCon.setState,
          );

        onClose();

        toast.success('Updated Device!');
      },
      onError: (error) => {
        toast.error(String(error));
      },
    },
  );
  const onSubmit = (data: any) => {
    if (!data.groupId || (data.groupId === 'None' && !device.group)) {
      return onClose();
    }

    if (data.groupId === 'None' || data.groupId === '' || !data.groupId) {
      const formData = {
        groupId: device.group || '',
        newDevices: [],
        deleteDevices: [device],
      };
      mututateGroupDevices(formData);
    } else {
      const formData = {
        groupId: findParentGroupIdByPath(data.groupId, groupTree) || '',
        newDevices: [device],
        deleteDevices: [],
      };
      mututateGroupDevices(formData);
    }
  };

  const defaultPath = getPath(
    getGroupNameById(device.group || '', groupTree) || '',
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper
          sx={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            maxWidth: '500px',
            minWidth: '300px',
            height: '150px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4,
          }}
        >
          <Typography variant="h3">Edit {device.name} group</Typography>

          <Controller
            name="groupId"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                // Find the matching object for the default value
                defaultValue={defaultPath}
                style={{ width: '80%' }}
                options={paths}
                getOptionLabel={(option) => option.split('/').join(' / ')}
                onChange={(event, newValue) => {
                  setValue('groupId', newValue || '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Edit device group..."
                  />
                )}
              />
            )}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ width: '200px', height: '40px' }}
          >
            {!isLoading ? <>Save Device Group</> : <CircularProgress />}
          </Button>
        </Paper>
      </form>
    </Modal>
  );
}
