import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { UserContext } from '../context/UserStateManager';
import { LineupType } from '../Enums';
import { ShowLineup } from '../admin/SystemLineupList';
import { GroupShowLineup } from '../orgs/orgTypes';

type props = {
  lineupType: string;
  open: boolean;
  onClose: (lineup: any[] | null) => void;
  systemLineups: ShowLineup[];
};

export default function LoadLineupModal({
  lineupType,
  open,
  onClose,
  systemLineups,
}: props) {
  const theme = useTheme();
  const userCon = useContext(UserContext);
  const [selectedLineup, setSelectedLineup] = useState(0);
  const [confirm, setConfirm] = useState(false);

  let lineup: (GroupShowLineup | ShowLineup)[] =
    lineupType === LineupType.MOOD_LIGHTING
      ? [
          ...(userCon.state.currentOrg?.org.orgLineups ?? []),
          ...(systemLineups || []),
        ]
      : userCon.state.currentOrg?.org.orgMusicLineups ?? [];

  const availableLineups = useMemo(() => lineup, [userCon, systemLineups]);

  const modalClose = (lineup: any[] | null) => {
    setConfirm(false);
    onClose(lineup);
  };

  return (
    <Modal
      sx={{ margin: theme.spacing(2) }}
      open={open}
      onClose={() => modalClose(null)}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '20vh auto',
          width: 'fit-content',
          minWidth: '250px',
        }}
      >
        <Typography variant="h2">Open Show Lineup</Typography>
        {!confirm && (
          <>
            <FormControlLabel
              label="Select Lineup:"
              labelPlacement="start"
              control={
                <FormControl style={{ marginLeft: '8px' }}>
                  <Select
                    value={selectedLineup}
                    onChange={(e) =>
                      setSelectedLineup(e.target.value as number)
                    }
                  >
                    {availableLineups.map(
                      (
                        obj: {
                          name: string;
                          lineup: any[];
                          scope?: string;
                        },
                        i,
                      ) => (
                        <MenuItem key={i} value={i}>
                          {obj?.scope === 'SYSTEM' && <b>System -&nbsp;</b>}
                          {obj.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              }
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="text"
                color="secondary"
                style={{ marginRight: '16px', color: '#818387' }}
                onClick={() => modalClose(null)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setConfirm(true)}
              >
                Open
              </Button>
            </div>
          </>
        )}
        {confirm && (
          <>
            <Typography variant="subtitle2">
              Are you sure you want to open this lineup?
            </Typography>
            <Typography variant="subtitle2">
              This will replace your current lineup.
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="text"
                color="secondary"
                style={{ marginRight: '16px', color: '#818387' }}
                onClick={() => modalClose(null)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  modalClose(availableLineups[selectedLineup].lineup)
                }
              >
                Replace
              </Button>
            </div>
          </>
        )}
      </Paper>
    </Modal>
  );
}
