import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { tUserContext } from '../context/UserStateManager';
import { Group, GroupShowLineup, GroupTreeNode } from './orgTypes';
import { DeviceModel } from '../devices/Devices';
import { EventSettings } from '../devices/DeviceSettingsObject';

//API CALLS
export const getGroups = async (userCon: tUserContext, currentOrg?: string) => {
  if (!userCon) return;

  let res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      (currentOrg ?? userCon.state.currentOrg?.org._id) +
      '/groups',
    {
      headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
    },
  );
  if (res.ok) {
    const groups = await res.json();
    return groups;
  }
  throw Error(res.statusText);
};

export const getGroupDevices = async (
  userCon: tUserContext,
  groupId: string,
) => {
  let res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL + '/groups/' + groupId + '/devices',
    {
      headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
    },
  );
  if (res.ok) {
    const devices = await res.json();
    return devices;
  }
  throw Error(res.statusText);
};

export const getUngroupDevices = async (userCon: tUserContext) => {
  let res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userCon.state.currentOrg?.org._id +
      '/ungrouped-devices',
    {
      headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
    },
  );
  if (res.ok) {
    const devices = await res.json();
    return devices;
  }
  throw Error(res.statusText);
};

export const updateGroupDetails = async (
  userCon: tUserContext,
  groupId: string,
  name: string,
  parentGroup: string,
) => {
  let res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL + '/groups/' + groupId,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        name: name,
        parentGroup: parentGroup === '' ? null : parentGroup,
      }),
    },
  );
  if (res.ok) {
    const group = await res.json();
    return group;
  }
  throw Error(res.statusText);
};

export const updateGroupDevices = async (
  userCon: tUserContext,
  groupId: string,
  newDevices: DeviceModel[],
  deleteDevices: DeviceModel[],
) => {
  const res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL + '/groups/' + groupId + '/devices',
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        newDevices: newDevices,
        deleteDevices: deleteDevices,
      }),
    },
  );

  if (res.status !== 200) {
    throw Error(res.statusText!);
  }
};

export const createGroupDetails = async (
  userCon: tUserContext,
  name: string,
  parentGroup: string,
  org: string,
) => {
  let res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL + '/groups/',
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        name: name,
        parentGroup: parentGroup === '' ? null : parentGroup,
        org: org,
      }),
    },
  );
  if (res.status < 400) {
    const group = await res.json();
    return group;
  }
  throw Error(res.statusText);
};

export const updateGroupFormSubmit = async (
  userCon: tUserContext,
  groupId: string,
  name: string,
  parentGroup: string,
  newDevices: DeviceModel[],
  deleteDevices: DeviceModel[],
) => {
  try {
    await updateGroupDetails(userCon, groupId, name, parentGroup);
    await updateGroupDevices(userCon, groupId, newDevices, deleteDevices);
  } catch (e) {
    throw e;
  }
};

export const createGroupFormSubmit = async (
  userCon: tUserContext,
  name: string,
  parentGroup: string,
  newDevices: DeviceModel[],
  org: string,
) => {
  try {
    const newGroup = await createGroupDetails(userCon, name, parentGroup, org);
    await updateGroupDevices(userCon, newGroup._id, newDevices, []);
  } catch (e) {
    throw e;
  }
};

export const deleteGroup = async (userCon: tUserContext, groupId: string) => {
  let res = await fetchLogoutOn401(
    userCon,
    process.env.REACT_APP_BACKEND_URL + '/groups/' + groupId,
    {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({}),
    },
  );
  if (res.status >= 400) {
    throw Error(res.statusText);
  }
};

/*
  GROUP LINEUP FUNCTIONS
*/

export async function addGroupLineup(
  userCon: tUserContext,
  groupId: string,
  lineup: GroupShowLineup,
) {
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/groups/${groupId}/lineup`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        name: lineup.name,
        lineup: lineup.lineup,
      }),
    },
  );
  if (!resp.ok) {
    if (resp.status === 400) {
      throw new Error('Error adding new lineup.');
    }
    throw new Error('Request unsuccessful');
  }
}

export async function updateGroupLineup(
  userCon: tUserContext,
  groupId: string,
  lineup: GroupShowLineup,
) {
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/groups/${groupId}/lineup/${lineup._id}`,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        name: lineup.name,
        lineup: lineup.lineup,
      }),
    },
  );
  if (resp.ok) {
    return resp.json();
  } else {
    if (resp.status === 400) {
      throw new Error('That name is already in use.');
    }
    throw new Error('Request unsuccessful');
  }
}

export async function deleteGroupLineup(
  lineupId: string,
  groupId: string,
  userCon: tUserContext,
) {
  if (!lineupId) return;
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/groups/${groupId}/lineup/${lineupId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
    },
  );
  if (resp.ok) return;
  else throw new Error('Group lineup delete failed');
}

export async function updateGroupLineupOverride(
  lineupId: string,
  groupId: string,
  userCon: tUserContext,
) {
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/groups/${groupId}/override/${lineupId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
    },
  );
  if (resp.ok) return;
  else throw new Error("Couldn't update grop lineup override!");
}

/*
  GROUP EVENT FUNCTIONS
*/

export async function updateGroupEvents(
  userCon: tUserContext,
  groupIds: string[],
  events: EventSettings[],
) {
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/groups/events`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({ events: events, groups: groupIds }),
    },
  );
  if (resp.ok) return;
  else throw new Error("Couldn't update grop events!");
}

//HELPER FUNCTIONS

/**
 * Given a list of groups, return a tree based on
 * the GroupTreeNode[] data struct
 *
 * @param groups a list of groups
 * @returns
 */
export function buildGroupTree(groups: Group[]): GroupTreeNode[] {
  if (!groups) return [];

  const groupMap: Record<string, GroupTreeNode> = {};

  groups.forEach((group) => {
    groupMap[group._id] = { group, children: [] };
  });

  const roots: GroupTreeNode[] = [];

  groups.forEach((group) => {
    if (group.parentGroup) {
      const parentNode = groupMap[group.parentGroup];
      if (parentNode) {
        parentNode.children.push(groupMap[group._id]);
      }
    } else {
      roots.push(groupMap[group._id]);
    }
  });

  return roots;
}

/**
 * Builds all possible path strings
 *
 * @param roots GroupTreeNode[] built from the function above
 * @returns All possible path strings
 */
export function buildAllPaths(roots: GroupTreeNode[]): string[] {
  const paths: string[] = [];

  function traverse(node: GroupTreeNode, path: string) {
    const newPath = path ? `${path}/${node.group.name}` : node.group.name;
    paths.push(newPath);
    node.children.forEach((child) => traverse(child, newPath));
  }

  roots.forEach((root) => traverse(root, ''));
  paths.push('None');

  return paths;
}

/**
 * Returns the lowest level group path a given groupName
 * belongs in.
 *
 * @param paths a list of all possible paths
 * @param groupName the name of the group
 * @returns the path string for a group name
 */
export function findGroupPathByName(
  paths: string[],
  groupName: string,
): string | null {
  for (const path of paths) {
    const pathParts = path.split('/');

    if (pathParts[pathParts.length - 1] === groupName) {
      if (pathParts.length > 1) {
        pathParts.pop();
        return pathParts.join('/');
      } else {
        return 'None';
      }
    }
  }

  return 'None';
}

/**
 * Given a path string, return the groupId of the nearest parent.
 *
 *
 * @param path a path string
 * @param groupTree a group tree (created by the build function above)
 * @returns either the parent groupId string or null
 */
export const findParentGroupIdByPath = (
  path: string,
  groupTree: GroupTreeNode[],
): string | null => {
  const pathParts = path.split('/').filter((part) => part);

  // Helper function to traverse the tree
  const traverseTree = (
    node: GroupTreeNode,
    parts: string[],
    index: number,
  ): string | null => {
    if (index >= parts.length) {
      return node.group._id;
    }

    const currentPart = parts[index];
    const childNode = node.children.find(
      (child) => child.group.name === currentPart,
    );

    if (childNode) {
      return traverseTree(childNode, parts, index + 1);
    }

    return null;
  };

  for (const root of groupTree) {
    if (root.group.name === pathParts[0]) {
      const result = traverseTree(root, pathParts, 1);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

/**
 * Finds the name of a group based on the given groupId.
 * @param groupId The ID of the group to find.
 * @param groupTree The root of the group tree.
 * @returns The name of the group if found, otherwise undefined.
 */
export function getGroupNameById(
  groupId: string,
  groupTree: GroupTreeNode[],
): string | undefined {
  const findGroupName = (nodes: GroupTreeNode[]): string | undefined => {
    for (const node of nodes) {
      if (node.group._id === groupId) {
        return node.group.name;
      }

      if (node.children && node.children.length > 0) {
        const result = findGroupName(node.children);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  return findGroupName(groupTree);
}

export function getGroupById(
  groupId: string,
  groupTree: GroupTreeNode[],
): Group | undefined {
  const findGroup = (nodes: GroupTreeNode[]): Group | undefined => {
    for (const node of nodes) {
      if (node.group._id === groupId) {
        return node.group;
      }

      if (node.children && node.children.length > 0) {
        const result = findGroup(node.children);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  return findGroup(groupTree);
}

export const findParentGroupByPath = (
  path: string,
  groupTree: GroupTreeNode[],
): Group | null => {
  const pathParts = path.split('/').filter((part) => part);

  // Helper function to traverse the tree
  const traverseTree = (
    node: GroupTreeNode,
    parts: string[],
    index: number,
  ): Group | null => {
    if (index >= parts.length) {
      return node.group;
    }

    const currentPart = parts[index];
    const childNode = node.children.find(
      (child) => child.group.name === currentPart,
    );

    if (childNode) {
      return traverseTree(childNode, parts, index + 1);
    }

    return null;
  };

  for (const root of groupTree) {
    if (root.group.name === pathParts[0]) {
      const result = traverseTree(root, pathParts, 1);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const findGroupTreeNodeByPath = (
  groups: GroupTreeNode[],
  path: string,
): GroupTreeNode | null => {
  const pathParts = path.split('/').filter(Boolean); // Split and remove empty parts

  let currentGroups = groups;
  let currentGroup: GroupTreeNode | null = null;

  for (const part of pathParts) {
    currentGroup = currentGroups.find((g) => g.group.name === part) || null;
    if (currentGroup) {
      currentGroups = currentGroup.children; // Move deeper into the tree
    } else {
      return null; // Path is invalid
    }
  }

  return currentGroup;
};

/**
 * Check to see if any groups have a lineupOverride equal to a param given
 * @param groups Group Tree
 * @param lineupOverride Lineup ID
 * @returns True if overriding, false if not.
 */
export function checkGroupLineupOverride(
  groups: GroupTreeNode[],
  lineupOverride: string,
  primaryOrSecondary: string,
): boolean {
  for (const groupNode of groups) {
    if (
      (primaryOrSecondary === 'primary'
        ? groupNode.group.groupLineupOverride
        : groupNode.group.secondaryGroupLineupOverride) === lineupOverride
    ) {
      return true;
    }
    if (
      checkGroupLineupOverride(
        groupNode.children,
        lineupOverride,
        primaryOrSecondary,
      )
    ) {
      return true;
    }
  }
  return false;
}

//Check to see if a lineup already has a group in it thats being
//overridden
export function checkMultipleOverride(
  groupTree: GroupTreeNode[],
  lineup: GroupShowLineup,
  primaryOrSecondary: string,
): boolean {
  for (const groupNode of groupTree) {
    if (primaryOrSecondary === 'primary') {
      if (
        lineup.groups.includes(groupNode.group._id) &&
        groupNode.group.groupLineupOverride
      ) {
        return true;
      }
    } else {
      if (
        lineup.groups.includes(groupNode.group._id) &&
        groupNode.group.secondaryGroupLineupOverride
      ) {
        return true;
      }
    }
    if (checkMultipleOverride(groupNode.children, lineup, primaryOrSecondary)) {
      return true;
    }
  }
  return false;
}
