import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserContext, tUserContext } from '../context/UserStateManager';
import { useMutation, useQuery } from 'react-query';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import ConfirmationModal from '../common/ConfirmationModal';
import DeviceCommentModal from './DeviceCommentModal';

export interface DeviceComment {
  _id: string;
  comment: string;
  time: Date;
  userID: string;
  userName: string;
}

async function getDeviceComments(
  userCon: tUserContext,
): Promise<DeviceComment[]> {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice?._id}/comments`,
    {
      headers: {
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
    },
  );
  if (res.ok) {
    return res.json();
  } else {
    throw new Error('Request unsuccessful');
  }
}

async function createDeviceComment(
  userCon: tUserContext,
  comment: string,
): Promise<void> {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice?._id}/comment`,
    {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + userCon.state.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ comment }),
    },
  );
  if (res.ok) {
    return;
  } else {
    throw new Error('Request unsuccessful');
  }
}

async function updateDeviceComment(
  userCon: tUserContext,
  commentID: string,
  comment: string,
): Promise<void> {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice?._id}/comment/${commentID}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + userCon.state.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ comment }),
    },
  );
  if (res.ok) {
    return;
  } else {
    throw new Error('Request unsuccessful');
  }
}

async function deleteDeviceComment(
  userCon: tUserContext,
  commentID: string,
): Promise<void> {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice?._id}/comment/${commentID}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
    },
  );
  if (res.ok) {
    return;
  } else {
    throw new Error('Request unsuccessful');
  }
}

export default function DeviceComments() {
  const userCon = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pageSize, setPageSize] = useState(5);
  const [formState, setFormState] = useState<{
    commentID: null | string;
    commentText: null | string;
    operation: null | 'create' | 'update' | 'delete';
  }>({
    commentID: null,
    commentText: null,
    operation: null,
  });
  const deviceComments = useQuery({
    queryKey: ['deviceComments', userCon.state.currentDevice?._id],
    queryFn: () => getDeviceComments(userCon),
  });
  const { mutate: createComment } = useMutation({
    mutationFn: ({ comment }: { comment: string }) =>
      createDeviceComment(userCon, comment),
    onSuccess: () => deviceComments.refetch(),
  });
  const { mutate: updateComment } = useMutation({
    mutationFn: ({
      commentID,
      comment,
    }: {
      commentID: string;
      comment: string;
    }) => updateDeviceComment(userCon, commentID, comment),
    onSuccess: () => deviceComments.refetch(),
  });
  const { mutate: deleteComment } = useMutation({
    mutationFn: ({ commentID }: { commentID: string }) =>
      deleteDeviceComment(userCon, commentID),
    onSuccess: () => deviceComments.refetch(),
  });

  const closeModal = () => {
    setFormState({ commentID: null, commentText: null, operation: null });
  };

  const columns: GridColDef[] = [
    {
      field: '',
      headerName: '',
      width: 40,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        userCon.state.user._id === params.row.userID && (
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setFormState({
                ...formState,
                commentID: params.id as string,
                commentText: params.row.comment,
              });
            }}
            style={{ padding: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
    },
    {
      field: 'userName',
      headerName: 'User',
      width: 200,
    },
    {
      field: 'time',
      headerName: 'Last Updated',
      width: 200,
      valueGetter: (value, row) =>
        moment(row.time)
          .tz(userCon.state.currentDevice?.timeZone ?? 'America/New York')
          .format('L LTS'),
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 600,
    },
  ];

  return (
    <Paper style={{ minHeight: '500px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setFormState({ ...formState, operation: 'create' });
          }}
        >
          Add Comment
        </Button>
      </div>
      <DataGrid
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize } },
        }}
        onPaginationModelChange={(newSize) => setPageSize(newSize.pageSize)}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        sx={{ margin: '8px 0', height: '610px' }}
        rows={deviceComments?.data ?? []}
        columns={columns}
        getRowId={(row) => row._id}
        getCellClassName={(params: GridCellParams<any, any, DeviceComment>) => {
          if (params.field === 'comment') {
            return 'custom-datagrid-comment-cell';
          }
          return '';
        }}
        rowHeight={100}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setFormState({ ...formState, operation: 'update' });
          }}
        >
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setFormState({ ...formState, operation: 'delete' });
          }}
        >
          <Typography color="error">Delete</Typography>
        </MenuItem>
      </Menu>
      <DeviceCommentModal
        open={formState.operation === 'create'}
        closeModal={closeModal}
        submitComment={(comment: string) => {
          closeModal();
          createComment({ comment });
        }}
        title="Add Comment"
        currentComment=""
      />
      <DeviceCommentModal
        open={formState.operation === 'update'}
        closeModal={closeModal}
        submitComment={(comment: string) => {
          const commentID = formState.commentID as string;
          closeModal();
          updateComment({ commentID, comment });
        }}
        title="Update Comment"
        currentComment={formState.commentText ?? ''}
      />
      <ConfirmationModal
        open={formState.operation === 'delete'}
        onClose={closeModal}
        onConfirm={() => {
          const commentID = formState.commentID as string;
          closeModal();
          deleteComment({ commentID });
        }}
        title="Delete Comment?"
        message="Are you sure you want to delete this comment?"
        confirmText="Delete"
      />
    </Paper>
  );
}
