import { Typography } from '@mui/material';

type CodeResponses = {
  statusCode: number;
  response: string;
}[];

type Props = {
  statusCode: number;
  codeResponses: CodeResponses;
};

export const StatusCodeResponse = ({ statusCode, codeResponses }: Props) => {
  for (const codeResponse of codeResponses) {
    if (statusCode === codeResponse.statusCode) {
      return (
        <div
          style={{
            padding: '0',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Typography variant="subtitle1" color="error">
            {codeResponse.response}
          </Typography>
        </div>
      );
    }
  }

  return null;
};

export default StatusCodeResponse;
