import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ShowConfiguration } from '../devices/DeviceSettingsObject';
import { ShowConfigMap, ShowName, ShowNameMap, WHITE_VALUE } from '../Enums';
import MultiColorPicker from '../configuration/MultiColorPicker';

type props<ControlType extends FieldValues> = {
  control: Control<ControlType>;
  setBlockSubmit: (arg0: boolean) => void;
  outsideEvent: null | any;
  showData: null | ShowConfiguration;
  basePath: string;
};

export function trimShowFields(show: ShowConfiguration) {
  if (ShowConfigMap[show.showName].maxColors === 0) delete show.colors;
  if (!ShowConfigMap[show.showName].direction) delete show.backward;
  if (!ShowConfigMap[show.showName].speed) delete show.speed;
}

export default function CustomerControlShowForm<
  ControlType extends FieldValues,
>({
  control,
  showData,
  setBlockSubmit,
  outsideEvent,
  basePath,
}: props<ControlType>) {
  const [selectedShow, setSelectedShow] = useState(
    showData?.showName ?? ShowName.SOLID,
  );

  return (
    <div>
      <Controller
        name={(basePath + 'showName') as Path<ControlType>}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            label="Show Type:"
            labelPlacement="start"
            control={
              <Select
                value={value}
                onChange={(e) => {
                  setSelectedShow(e.target.value as ShowName);
                  onChange(e);
                }}
              >
                {Object.keys(ShowNameMap).map((val) => (
                  <MenuItem key={val} value={val}>
                    {ShowNameMap[val as ShowName]}
                  </MenuItem>
                ))}
              </Select>
            }
          />
        )}
      />

      {ShowConfigMap[selectedShow].maxColors > 0 && (
        <Controller
          name={(basePath + 'colors') as Path<ControlType>}
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiColorPicker
              minColors={ShowConfigMap[selectedShow].minColors}
              maxColors={ShowConfigMap[selectedShow].maxColors}
              value={(value ?? [...WHITE_VALUE]) as [number, number, number][]}
              onChange={onChange}
              blockSubmit={setBlockSubmit}
              outsideEvent={outsideEvent}
            />
          )}
        />
      )}
      {ShowConfigMap[selectedShow].speed && (
        <Controller
          name={(basePath + 'speed') as Path<ControlType>}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControlLabel
              label="Speed:"
              labelPlacement="start"
              control={
                <Select value={value} onChange={onChange}>
                  {Array(10)
                    .fill(1)
                    .map((_, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1 + ''}
                      </MenuItem>
                    ))}
                </Select>
              }
            />
          )}
          rules={{
            validate: (value) => !isNaN(parseInt(value)) || 'Speed Required',
          }}
        />
      )}
      {ShowConfigMap[selectedShow].speed && <div style={{ width: '32px' }} />}

      {ShowConfigMap[selectedShow].direction && (
        <Controller
          name={(basePath + 'backward') as Path<ControlType>}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControlLabel
              label="Backwards"
              style={{ display: 'initial' }}
              control={
                <Checkbox
                  checked={value as boolean}
                  value={value}
                  color="primary"
                  onChange={onChange}
                />
              }
            />
          )}
        />
      )}
      <Typography variant="h3">Customer Control App:</Typography>
      <Controller
        name={(basePath + 'name') as Path<ControlType>}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControlLabel
            label="Name"
            labelPlacement="start"
            control={
              <TextField value={value} color="primary" onChange={onChange} />
            }
          />
        )}
      />
      <Controller
        name={(basePath + 'description') as Path<ControlType>}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControlLabel
            label="Description"
            labelPlacement="start"
            control={
              <TextField value={value} color="primary" onChange={onChange} />
            }
          />
        )}
      />
      <Controller
        name={(basePath + 'imageUrl') as Path<ControlType>}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControlLabel
            label="Image URL"
            labelPlacement="start"
            control={
              <TextField value={value} color="primary" onChange={onChange} />
            }
          />
        )}
      />
    </div>
  );
}
