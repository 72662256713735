import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Paper,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { useContext, useMemo, useRef, useState } from 'react';
import { tUserContext, UserContext } from '../context/UserStateManager';
import { ConditionType } from '../Enums';
import { DeviceSettingsContext } from './DevicePage';
import { DeviceStatus } from '../devices/Devices';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { InputCondition } from '../devices/DeviceSettingsObject';
import {
  DEFAULT_DEVICE_TYPE_WORDS_PRIMARY,
  DEFAULT_DEVICE_TYPE_WORDS_SECONDARY,
  DEVICE_TYPE_CHECK,
  OTHER_DEVICE_TYPE_WORDS_PRIMARY,
  OTHER_DEVICE_TYPE_WORDS_SECONDARY,
} from '../../constants';
import QuickSettingButton from './QuickSettingButton';
import { useMutation } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';
import { ExpandMore } from '@mui/icons-material';
import { sendStatusLog, startStopQuickSettings } from './helpers';

type StatusDto = {
  time: string;
  source: string;
  type: string;
  description: string;
}[];
type StatusData = {
  index: number;
  time: moment.Moment;
  source: string;
  type: string;
  description: string;
}[];

export const fetchStatusJson = (userCon: tUserContext) => {
  if (userCon.state.currentDevice) {
    return fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL +
        '/devices/status/' +
        userCon.state.currentDevice._id,
      {
        headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
      },
    ).then((res) => {
      if (res.ok) return res.json();
      else throw Error;
    });
  } else
    return new Promise(() => {
      throw Error;
    });
};

export default function DeviceStatusPage() {
  const theme = useTheme();
  const { configuration } = useContext(DeviceSettingsContext);
  const userCon = useContext(UserContext);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [status, setStatus] = useState<DeviceStatus | null>(null);
  const [liveStatus, setLiveStatus] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const listener = useRef<NodeJS.Timeout | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const device = userCon.state.currentDevice;
  const deviceConnected = useMemo(() => !!status, [status]);

  const [statusLog, setStatusLog] = useState<StatusData>([]);

  const inputName = useCallback(
    (input: number) => {
      for (let trigger of configuration.triggers) {
        if (trigger.condition.input === input) return trigger.name;
      }
      if (
        configuration.settings.systemEnable.conditionType ===
          ConditionType.INPUT_CONDITION &&
        (configuration.settings.systemEnable.condition as InputCondition)
          .input === input
      ) {
        return 'System';
      }
      if (
        configuration.settings.dimming.conditionType ===
          ConditionType.INPUT_CONDITION &&
        (configuration.settings.dimming.condition as InputCondition).input ===
          input
      ) {
        return 'Dimming';
      }
      if (
        configuration.settings.secondaryMoodShowEnable.conditionType ===
          ConditionType.INPUT_CONDITION &&
        (
          configuration.settings.secondaryMoodShowEnable
            .condition as InputCondition
        ).input === input
      ) {
        return configuration.controllerType === DEVICE_TYPE_CHECK
          ? DEFAULT_DEVICE_TYPE_WORDS_SECONDARY
          : OTHER_DEVICE_TYPE_WORDS_SECONDARY;
      }
      if (
        configuration.settings.moodShowEnable.conditionType ===
          ConditionType.INPUT_CONDITION &&
        (configuration.settings.moodShowEnable.condition as InputCondition)
          .input === input
      ) {
        return configuration.controllerType === DEVICE_TYPE_CHECK
          ? DEFAULT_DEVICE_TYPE_WORDS_PRIMARY
          : OTHER_DEVICE_TYPE_WORDS_PRIMARY;
      }
      if (
        configuration.musicControl.musicShowEnable.conditionType ===
          ConditionType.INPUT_CONDITION &&
        (configuration.musicControl.musicShowEnable.condition as InputCondition)
          .input === input
      ) {
        return 'Music Control';
      }
      if (
        configuration.settings.maintenanceMode.conditionType ===
          ConditionType.INPUT_CONDITION &&
        (configuration.settings.maintenanceMode.condition as InputCondition)
          .input === input
      ) {
        return 'Maintenance';
      }
      return 'Input ' + input;
    },
    [configuration],
  );

  const handleFetchStatus = useCallback(() => {
    fetchStatusJson(userCon)
      .then((json) => {
        setStatus(json as DeviceStatus);
      })
      .catch((e) => {
        setStatus(null);
        if (listener.current) {
          clearInterval(listener.current);
          setLiveStatus(false);
        }
        console.log('Unable to connect to controller.');
      });
  }, [userCon]);

  const fetchErrors = useCallback(() => {
    fetchLogoutOn401(
      userCon,
      process.env.REACT_APP_BACKEND_URL +
        '/devices/errors/' +
        userCon.state.currentDevice?._id,
      {
        headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
      },
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error;
      })
      .then((json) =>
        setStatusLog(
          (json as StatusDto)
            .map((error, i) => ({
              index: i,
              time: moment(error.time),
              source: error.source,
              type: error.type,
              description: error.description,
            }))
            .reverse(),
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCon.state.currentDevice?._id, userCon.state.jwtToken]);

  useEffect(() => {
    handleFetchStatus();
    fetchErrors();
    return () => {
      if (listener.current) clearInterval(listener.current);
      if (timeout.current) clearInterval(timeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: sendNewStatusLog } = useMutation({
    mutationFn: ({ userCon, desc }: { userCon: tUserContext; desc: string }) =>
      sendStatusLog(userCon, desc),
    onSuccess: () => fetchErrors(),
  });

  const getCurrentHighlight = useMemo((): [number, string, boolean] => {
    if (status?.maintenanceQuickChangeActive)
      return [0, 'quick-maintenance', false];
    else if (status?.moodLightingQuickChangeActive)
      return [1, 'quick-showlineup', true];
    else if (status?.secondaryMoodLightingQuickChangeActive)
      return [2, 'quick-showlineup', false];
    else return [-1, '', false];
  }, [status]);

  const { mutate: quickSettingChange, isLoading: quickSettingLoading } =
    useMutation({
      mutationFn: ({
        userCon,
        startStop,
        maintOrShow,
        dayNight,
      }: {
        userCon: tUserContext;
        startStop: string;
        maintOrShow: string;
        dayNight?: boolean;
      }) => startStopQuickSettings(userCon, startStop, maintOrShow, dayNight),
      onSuccess: (data) => {
        if (data) {
          let statusText = '';
          if (data.maintOrShow === 'quick-maintenance') {
            statusText = 'maintenance lighting';
          } else {
            if (data.dayNight === true) {
              statusText =
                userCon.state.currentDevice?.configuration.controllerType ===
                DEVICE_TYPE_CHECK
                  ? DEFAULT_DEVICE_TYPE_WORDS_PRIMARY
                  : OTHER_DEVICE_TYPE_WORDS_PRIMARY;
            } else {
              statusText =
                userCon.state.currentDevice?.configuration.controllerType ===
                DEVICE_TYPE_CHECK
                  ? DEFAULT_DEVICE_TYPE_WORDS_SECONDARY
                  : OTHER_DEVICE_TYPE_WORDS_SECONDARY;
            }
          }

          sendNewStatusLog({
            userCon: userCon,
            desc:
              'Quick settings -  ' +
              data.startStop +
              ' ' +
              statusText.toLocaleLowerCase(),
          });

          handleFetchStatus();

          toast.success(
            'Success - ' +
              data.startStop +
              ' ' +
              statusText.toLocaleLowerCase(),
          );
        }
      },
      onError: (e) => {
        toast.error('Failed to change quick settings.');
      },
    });

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'time',
        headerName: 'Time',
        width: 200,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params, row) =>
          device?.timeZone
            ? moment(row.time)
                .tz(device?.timeZone ?? '')
                .format('L LTS')
            : 'No set timezone',
      },
      {
        field: 'source',
        headerName: 'Source',
        filterable: false,
        disableColumnMenu: true,
        width: 100,
      },
      {
        field: 'description',
        headerName: 'Message',
        width: 600,
      },
    ],
    [device?.timeZone],
  );

  if (!device) return <div></div>;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
          marginBottom: theme.spacing(2),
        },
      }}
    >
      {/* Quick Settings Bar  */}
      {deviceConnected && userCon.state.currentOrg?.org.showQuickChangeBar && (
        <Accordion
          defaultExpanded={userCon.state.currentOrg?.role !== 'ORG_ADMIN'}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            Quick Access Settings
          </AccordionSummary>

          <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '20px',
                }}
                className="QuickChangeSettingsContainer"
              >
                <QuickSettingButton
                  disabled={quickSettingLoading}
                  active={getCurrentHighlight[0] === 1}
                  onClick={() => {
                    quickSettingChange({
                      userCon: userCon,
                      startStop: status?.moodLightingQuickChangeActive
                        ? 'stop'
                        : 'start',
                      maintOrShow: 'quick-showlineup',
                      dayNight: true,
                    });
                  }}
                  title={
                    device.configuration.controllerType === DEVICE_TYPE_CHECK
                      ? DEFAULT_DEVICE_TYPE_WORDS_PRIMARY
                      : OTHER_DEVICE_TYPE_WORDS_PRIMARY
                  }
                />
                <QuickSettingButton
                  disabled={quickSettingLoading}
                  active={getCurrentHighlight[0] === 2}
                  onClick={() => {
                    quickSettingChange({
                      userCon: userCon,
                      startStop: status?.secondaryMoodLightingQuickChangeActive
                        ? 'stop'
                        : 'start',
                      maintOrShow: 'quick-showlineup',
                      dayNight: false,
                    });
                  }}
                  title={
                    device.configuration.controllerType === DEVICE_TYPE_CHECK
                      ? DEFAULT_DEVICE_TYPE_WORDS_SECONDARY
                      : OTHER_DEVICE_TYPE_WORDS_SECONDARY
                  }
                />

                {device.configuration.controllerType === DEVICE_TYPE_CHECK && (
                  <QuickSettingButton
                    disabled={quickSettingLoading}
                    active={getCurrentHighlight[0] === 0}
                    onClick={() => {
                      quickSettingChange({
                        userCon: userCon,
                        startStop: status?.maintenanceQuickChangeActive
                          ? 'stop'
                          : 'start',
                        maintOrShow: 'quick-maintenance',
                      });
                    }}
                    title={'Maintenance Lighting'}
                  />
                )}
              </div>
              <span style={{ fontSize: '14px', marginTop: '20px' }}>
                Note: Quick access settings will automatically turn off after 4
                hours if not turned off manually.
              </span>
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      <Paper>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '450px',
              }}
            >
              <Typography variant="h3">Connection Status</Typography>
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '20px',
                  backgroundColor: deviceConnected ? '#11E300' : '#bebebe',
                }}
              />
            </div>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '450px',
                flexWrap: 'wrap',
              }}
            >
              <Typography variant="h3">Last Check-in: </Typography>
              <Typography style={{ marginLeft: 8 }}>
                {device.lastCheckin && device.timeZone
                  ? moment(device.lastCheckin)
                      .tz(device.timeZone)
                      .format('LLL z')
                  : 'Unknown'}
              </Typography>
            </div>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '450px',
              }}
            >
              <Typography variant="h3">Event Active</Typography>
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '20px',
                  backgroundColor:
                    status && status.eventActive ? '#11E300' : '#bebebe',
                }}
              />
            </div>
          </Grid>
          {status && status.eventActive && (
            <Grid size={{ xs: 12, md: 6 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  maxWidth: '450px',
                }}
              >
                <Typography variant="h3">Active Event Name: </Typography>
                <Typography>{status?.eventActive}</Typography>
              </div>
            </Grid>
          )}
          <Grid size={{ xs: 12, md: 6 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '450px',
              }}
            >
              <Typography variant="h3">System Enabled</Typography>
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '20px',
                  backgroundColor:
                    status && status.systemEnabled ? '#11E300' : '#bebebe',
                }}
              />
            </div>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '450px',
              }}
            >
              <Typography variant="h3">Dimming Active</Typography>
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '20px',
                  backgroundColor:
                    status && status.dimmingActive ? '#11E300' : '#bebebe',
                }}
              />
            </div>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '450px',
              }}
            >
              <Typography variant="h3">
                {configuration.controllerType === DEVICE_TYPE_CHECK
                  ? DEFAULT_DEVICE_TYPE_WORDS_PRIMARY + ' Enabled'
                  : OTHER_DEVICE_TYPE_WORDS_PRIMARY + ' Enabled'}
              </Typography>
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '20px',
                  backgroundColor:
                    status && status.moodLightingEnabled
                      ? '#11E300'
                      : '#bebebe',
                }}
              />
            </div>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '450px',
              }}
            >
              <Typography variant="h3">Troubleshooting Active</Typography>
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '20px',
                  backgroundColor:
                    status && status.debugEnabled ? '#11E300' : '#bebebe',
                }}
              />
            </div>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '450px',
              }}
            >
              <Typography variant="h3">
                {configuration.controllerType === DEVICE_TYPE_CHECK
                  ? DEFAULT_DEVICE_TYPE_WORDS_SECONDARY + ' Enabled'
                  : OTHER_DEVICE_TYPE_WORDS_SECONDARY + ' Enabled'}
              </Typography>
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '20px',
                  backgroundColor:
                    status && status.secondaryMoodLightingEnabled
                      ? '#11E300'
                      : '#bebebe',
                }}
              />
            </div>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '450px',
              }}
            >
              <Typography variant="h3">Maintenance Lighting Active</Typography>
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '20px',
                  backgroundColor:
                    status && status.maintenanceLightingActive
                      ? '#11E300'
                      : '#bebebe',
                }}
              />
            </div>
          </Grid>
          {status?.hasOwnProperty('customerControlActive') &&
            device.ccEnabled && (
              <Grid size={{ xs: 12, md: 6 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxWidth: '450px',
                  }}
                >
                  <Typography variant="h3">Customer Control Active</Typography>
                  <div
                    style={{
                      height: '20px',
                      width: '20px',
                      borderRadius: '20px',
                      backgroundColor:
                        status && status.customerControlActive
                          ? '#11E300'
                          : '#bebebe',
                    }}
                  />
                </div>
              </Grid>
            )}
          {device.musicEnabled && (
            <Grid size={{ xs: 12, md: 6 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  maxWidth: '450px',
                }}
              >
                <Typography variant="h3">Music Control Enabled</Typography>
                <div
                  style={{
                    height: '20px',
                    width: '20px',
                    borderRadius: '20px',
                    backgroundColor:
                      status && status.musicControlEnabled
                        ? '#11E300'
                        : '#bebebe',
                  }}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>

      {deviceConnected && (
        <Paper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h3">Input Status</Typography>
            <FormControlLabel
              label="Live Inputs"
              labelPlacement="end"
              style={{ marginTop: '-8px' }}
              control={
                <Switch
                  checked={liveStatus}
                  color="primary"
                  onChange={() => {
                    if (!liveStatus) {
                      listener.current = setInterval(handleFetchStatus, 500);
                      timeout.current = setTimeout(() => {
                        setLiveStatus(false);
                        clearInterval(listener.current as NodeJS.Timeout);
                      }, 4 * 60 * 60 * 1000); // 4 hour timeout
                    } else {
                      clearInterval(listener.current as NodeJS.Timeout);
                    }
                    setLiveStatus(!liveStatus);
                  }}
                />
              }
            />
          </div>
          <br />
          <Grid container spacing={3}>
            {status &&
              status.inputStates.map((state, index) => (
                <Grid
                  size={{ xs: 12, md: 6 }}
                  style={{ display: 'flex' }}
                  key={index}
                >
                  <div
                    style={{
                      height: '20px',
                      width: '20px',
                      borderRadius: '20px',
                      backgroundColor: state ? '#11E300' : '#bebebe',
                    }}
                  />
                  <div style={{ width: '8px' }} />
                  <Typography variant="h4">{inputName(index + 1)}</Typography>
                </Grid>
              ))}
          </Grid>
        </Paper>
      )}
      <Paper>
        <Typography variant="h3">Status Log</Typography>
        <Typography>
          {`Times in controller timezone: ${
            device.timeZone
              ? moment().tz(device.timeZone)?.format('z')
              : 'Unknown'
          }`}
        </Typography>
        <DataGrid
          sx={{ height: '89%' }}
          initialState={{
            pagination: { paginationModel: { pageSize: pageSize } },
          }}
          onPaginationModelChange={(newSize) => {
            setPageSize(newSize.pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          density="compact"
          rows={statusLog}
          columns={columns}
          getRowId={(row) => row.index}
          ref={(n) => {
            tableRef.current = n;
          }}
        />
      </Paper>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
