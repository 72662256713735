import {
  Button,
  Modal,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

type props = {
  open: boolean;
  closeModal: () => void;
  submitComment: (comment: string) => void;
  title: string;
  currentComment?: string;
};

export default function DeviceCommentModal({
  open,
  closeModal,
  submitComment,
  title,
  currentComment = '',
}: props) {
  const theme = useTheme();
  const { control, handleSubmit, reset } = useForm<{ comment: string }>();

  return (
    <Modal
      sx={{ margin: theme.spacing(2) }}
      open={open}
      onClose={() => {
        reset();
        closeModal();
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '20vh auto',
          maxWidth: '600px',
          gap: '10px',
        }}
      >
        <form
          onSubmit={handleSubmit(({ comment }) => {
            submitComment(comment);
            reset();
            closeModal();
          })}
        >
          <Typography variant="h2">{title}</Typography>
          <Controller
            name="comment"
            defaultValue={currentComment}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                style={{ width: '100%', margin: '16px 0 8px 0' }}
                label="Comment"
                multiline
                rows={4}
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Required',
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: theme.spacing(1),
            }}
          >
            <Button
              variant="text"
              color="secondary"
              style={{ marginRight: '16px', color: '#818387' }}
              onClick={() => {
                reset();
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#F0142F', color: '#ffffff' }}
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </Paper>
    </Modal>
  );
}
