import { Paper, Typography } from '@mui/material';

type props = {
  showBanner?: boolean;
  children?: any;
};

export default function OrgLineupOverrideBanner({
  showBanner,
  children,
}: props) {
  return (
    <div>
      {showBanner && (
        <div>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '10px',
              backgroundColor: 'yellow',
            }}
          >
            <Typography variant="h3" style={{ marginBottom: '8px' }}>
              Group Lineup Sync is currently active
            </Typography>
            {children}
          </Paper>
          <br />
        </div>
      )}
    </div>
  );
}
