import { TableCell, IconButton, Icon } from '@mui/material';
import { FolderOpen, Settings } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { GroupTreeNode } from '../orgTypes';

interface GroupTableRowProps {
  groupNode: GroupTreeNode;
  handleRowOpen: Function;
  groupId: string;
  rowOpen: string[];
  openSettings: Function;
  setEditGroup: Function;
}

export function GroupTableRow({
  groupNode,
  handleRowOpen,
  rowOpen,
  groupId,
  openSettings,
  setEditGroup,
}: GroupTableRowProps) {
  return (
    <>
      <TableCell width={1} style={{ padding: '2px' }}>
        <Icon
          color="primary"
          style={{
            paddingLeft: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FolderOpen />
        </Icon>
      </TableCell>
      <TableCell
        style={{ cursor: 'pointer' }}
        colSpan={6}
        onClick={() => {
          handleRowOpen();
        }}
      >
        {groupNode.group.name}
      </TableCell>
      <TableCell width={1}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            handleRowOpen();
          }}
        >
          {rowOpen.includes(groupId) ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      </TableCell>

      <TableCell width={1} style={{ padding: '2px' }}>
        <IconButton
          onClick={() => {
            openSettings();
            setEditGroup(groupNode.group);
          }}
        >
          <Settings />
        </IconButton>
      </TableCell>
    </>
  );
}
