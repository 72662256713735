//MOOD LIGHTING DEVICE TYPE WORDS AND CHECKS:
export const DEVICE_TYPE_CHECK = "DEFAULT_TYPE";

export const DEFAULT_DEVICE_TYPE_WORDS_NORMAL = "Mood Lighting"
export const DEFAULT_DEVICE_TYPE_WORDS_PRIMARY = "Daytime Mood Lighting"
export const DEFAULT_DEVICE_TYPE_WORDS_SECONDARY = "Nighttime Mood Lighting"

export const OTHER_DEVICE_TYPE_WORDS_NORMAL = "Show Lineup"
export const OTHER_DEVICE_TYPE_WORDS_PRIMARY = "Primary Show Lineup"
export const OTHER_DEVICE_TYPE_WORDS_SECONDARY = "Secondary Show Lineup"


