import {
  Button,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState, useCallback } from 'react';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import {
  refreshUserContext,
  setNoAuth,
  UserContext,
} from '../context/UserStateManager';

const RegisterController = () => {
  const [message, setMessage] = useState('');
  const [entered, setEntered] = useState(false);
  const [deviceID, setDeviceID] = useState('');
  const [confirmID, setConfirmID] = useState('');
  const [error, setError] = useState({ deviceID: false, confirmID: false });
  const userCon = useContext(UserContext);

  const handleRegisterController = useCallback(() => {
    if (deviceID.slice(-6) !== confirmID) {
      setMessage('Device IDs Do Not Match');
    } else {
      fetchLogoutOn401(
        userCon,
        `${process.env.REACT_APP_BACKEND_URL}/users/register/${deviceID}`,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + userCon.state.jwtToken,
          },
        },
      )
        .then((res) => res.json())
        .then((body) => {
          if (body.statusCode === 403) {
            setNoAuth(userCon);
            return;
          }
          refreshUserContext(userCon.state, userCon.setState);
          setMessage(body.message);
        })
        .catch((e) => console.log(e));
    }

    setEntered(false);
    setDeviceID('');
    setConfirmID('');
    setError({ deviceID: false, confirmID: false });
  }, [deviceID, confirmID, userCon]);

  return (
    <Paper>
      <Typography variant="h2">Register a Controller</Typography>
      <div>
        {!entered ? (
          <>
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                label=""
                labelPlacement="start"
                control={
                  <TextField
                    value={deviceID}
                    onChange={(e) => {
                      setMessage('');
                      setError({ ...error, deviceID: false });
                      setDeviceID(e.target.value);
                    }}
                    label="Enter Device ID"
                    error={error.deviceID}
                    helperText={error.deviceID ? 'Required' : null}
                  />
                }
              />
            </div>
            <Button
              color="primary"
              style={{ margin: '16px 0' }}
              variant="contained"
              onClick={() => {
                if (!deviceID) {
                  setError({ ...error, deviceID: true });
                  return;
                }
                setEntered(true);
              }}
            >
              Enter
            </Button>
          </>
        ) : (
          <>
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                label="Please Re-enter the Last 6 Characters"
                labelPlacement="top"
                style={{ alignItems: 'flex-start', margin: '8px 0' }}
                control={
                  <TextField
                    value={confirmID}
                    onChange={(e) => {
                      setConfirmID(e.target.value);
                      setError({ ...error, confirmID: false });
                    }}
                    error={error.confirmID}
                    helperText={error.confirmID ? 'Required' : null}
                  />
                }
              />
            </div>
            <Button
              color="primary"
              style={{ margin: '16px 0' }}
              variant="contained"
              onClick={() => {
                if (!confirmID) {
                  setError({ ...error, confirmID: true });
                  return;
                }
                handleRegisterController();
              }}
            >
              Confirm
            </Button>
          </>
        )}
      </div>
      <Typography
        variant="h6"
        style={
          message === 'Registered successfully'
            ? { color: 'green' }
            : { color: 'red' }
        }
      >
        {message}
      </Typography>
    </Paper>
  );
};

export default RegisterController;
