import { setNoAuth, tUserContext } from '../context/UserStateManager';

export async function fetchLogoutOn401(
  userCon: tUserContext,
  url: string,
  fetchOptions?: RequestInit | undefined,
) {
  const response = await fetch(url, fetchOptions);
  if (response.status === 401) setNoAuth(userCon);
  return response;
}
