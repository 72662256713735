import {
  AppBar,
  Button,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MouseEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { trimShowFields } from '../configuration/ShowForm';
import {
  CustomerControlShowConfiguration,
  defaultCustomerControlShowConfiguration,
} from './CustomerControlShowLineup';
import CustomerControlShowForm from './CustomerControlShowForm';

type props = {
  closeDialog: () => void;
  showData: null | CustomerControlShowConfiguration;
  submitShow: (show: CustomerControlShowConfiguration) => void;
};

export default function ShowDialog({
  closeDialog,
  showData,
  submitShow,
}: props) {
  const theme = useTheme();
  const history = useHistory();
  const { control, handleSubmit } = useForm<CustomerControlShowConfiguration>({
    defaultValues: showData ?? { ...defaultCustomerControlShowConfiguration },
  });
  const [outsideEvent, setOutsideEvent] = useState<null | MouseEvent>(null);
  const [blockSubmit, setBlockSubmit] = useState(false);

  useEffect(() => {
    // push current history so pop doesn't go back a page
    history.push(history.location);
    // close dialog on back button, since we pushed this goes back to the same page
    return history.listen((_, action) => {
      if (action === 'POP') {
        closeDialog();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{ minHeight: '100%', backgroundColor: '#F4F7FC' }}
      onClick={(e) => setOutsideEvent(e)}
    >
      <AppBar
        color="secondary"
        sx={{ height: `68px`, borderBottom: '1px solid #e0e0e0' }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {showData ? 'Edit Show' : 'Add Show'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: '68px' }} />
      <div
        style={{
          margin: 'auto',
          marginTop: theme.spacing(2),
          maxWidth: `min(400px, calc(100% - ${
            parseInt(theme.spacing(2)) * 2
          }px))`,
        }}
      >
        <Paper>
          <form
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            onSubmit={(e) => {
              e.stopPropagation();
              if (blockSubmit) e.preventDefault();
              else
                handleSubmit((show) => {
                  trimShowFields(show);
                  submitShow(show);
                  closeDialog();
                })(e);
            }}
          >
            <CustomerControlShowForm<CustomerControlShowConfiguration>
              control={control}
              setBlockSubmit={setBlockSubmit}
              outsideEvent={outsideEvent}
              basePath=""
              showData={showData}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginLeft: 'auto' }}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  );
}
