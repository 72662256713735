import { Typography } from '@mui/material';

export function ZoneTable({ pixels }: { pixels: number[][] }) {
  try {
    return (
      <table style={{ borderCollapse: 'collapse', textAlign: 'right' }}>
        {pixels.map((pixelArray, index) => (
          <tr>
            <td style={{ border: '1px solid black', padding: '2px 4px' }}>
              <b>{index + 1}</b>
            </td>
            {pixelArray.map((pixel) => (
              <td style={{ border: '1px solid black', padding: '2px 4px' }}>
                {pixel}
              </td>
            ))}
          </tr>
        ))}
      </table>
    );
  } catch (e) {
    return <Typography>Invalid zone json</Typography>;
  }
}
