import useTitle from '../../hooks/useTitle.hook';
import CustomerControlShowLineup from '../customer-control/CustomerControlShowLineup';
import Devices from '../devices/Devices';
import TabNavigation from '../navigation/TabNavigation';
import Orgs from '../orgs/Orgs';
import Users from '../users/Users';
import SystemLineupList from './SystemLineupList';

function Admin() {
  useTitle('Admin');

  return (
    <div>
      <TabNavigation
        title="System Settings"
        content={[
          { title: 'CONTROLLERS', component: <Devices admin /> },
          { title: 'USERS', component: <Users admin /> },
          { title: 'ORGANIZATIONS', component: <Orgs /> },
          { title: 'SYSTEM LINEUPS', component: <SystemLineupList /> },
          { title: 'CUSTOMER CONTROL', component: <CustomerControlShowLineup />,},
        ]}
      />
    </div>
  );
}
export default Admin;
